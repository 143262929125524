import { ITransactionHistoryData } from "../api/getTransactionHistory";

/**Handles the filtering of the custom date picker */
export const filterCustomDates = (fromDate: number, toDate: number, transactions: Array<ITransactionHistoryData>) => {
    let filteredArray = transactions.filter((transaction: ITransactionHistoryData) => {
        const transactionDate = new Date(transaction.effectiveDate).getTime()
        if(transactionDate >= fromDate && transactionDate <= toDate){
            return transaction
        }
        return false;
    })
    
    return filteredArray
}

