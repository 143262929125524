import './ChangePassword.scss'
import { IChangePasswordProps } from './interfaces/IChangePasswordProps'
import arrowLeft from '../../../../assets/arrowLeft.svg'
import blueEye from '../../../../assets/blueEye.svg'
import greenCheckmark from '../../../../assets/greenCheckmark.svg'
import redCircleX from '../../../../assets/redCircleX.svg'
import { useChangePassword } from '../../../../useHooks/componentHooks/useChangePassword/useChangePassword'
import arrowLeftBlue from '../../../../assets/arrowLeftBlue.svg'
import redExclamationCircle from '../../../../assets/redExclamationCircle.svg'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'

/**Renders the change password flow found in user profile security settings */
const ChangePassword = (props: IChangePasswordProps) => {
    const { setChangePassword } = props
    const { windowState, currentPassword, handleCurrentPassword, newPassword, handleNewPassword, retypedPassword, handleRetypedPassword,
            enableSave, showCurrent, setShowCurrent, showNew, setShowNew, showRetyped, setShowRetyped, lettersPassed, numberPassed,
            specialPassed, lengthPassed, checkTotalPassed, handleSave, type, message, ShowCurrentPasswordError, handleRetypedOnBlur, showRetypeError, resetAllValues } = useChangePassword(setChangePassword)

    return (
        <div className="change-password-wrap">
            {message !== "" && <ToastMessageDB type={type} message={message} /> }
            {windowState !== "Mobile" ?
                <div className="change-header">
                    <div className="text-wrap">
                        <img className="header-image" src={arrowLeft} alt="back arrow" onClick={() => {resetAllValues(); setChangePassword(false)}}/>
                        <span className="header-text regular" onClick={() => {resetAllValues(); setChangePassword(false)}}>Security settings</span>
                    </div>
                </div>
                :
                <div className="password-mobile-header">
                    <img src={arrowLeftBlue} alt="blue back arrow" className="header-image" onClick={() => {resetAllValues(); setChangePassword(false)}}/>
                    <span className="blue-text regular" onClick={() => {resetAllValues(); setChangePassword(false)}}>Security settings</span>
                </div>
            }
            <div className={windowState !== "Mobile" ? "password-card-wrapper" : "password-card-wrapper mobile"} >
                <div className={windowState !== "Mobile" ? "data-wrap" : "data-wrap mobile"} >
                    <span className="header-text h2">Change password</span>
                    <span className="sub-header-text regular">To change your password, enter your current password, then enter and re-enter your new password and select 'Save.'</span>
                    <div className="bottom-border" />
                    {ShowCurrentPasswordError &&
                        <div className="error-wrapper">
                            <img className="image" src={redExclamationCircle} alt="red circle exclamation mark" />
                            <div className="message-stack">
                                <span className="header-text boldLarge">Your current password is incorrect</span>
                                <span className="sub-header-text regular">Please check that you entered your current password correctly and try again.</span>
                            </div>
                        </div>
                    }
                    <div className="input-wrap">
                        <div className="label-wrap">
                            <label htmlFor="current-password" className="label-text small">Current password</label>
                            <div className="right-side" onClick={() => setShowCurrent(!showCurrent)}>
                                <span className="blue-text small">{showCurrent ? "hide" : "show"}</span>
                                <img className="eye-image" src={blueEye} alt="blue eye" />
                            </div>
                        </div>
                        <input 
                            id="current-password" 
                            type={showCurrent ? "text" : "password"}
                            className={!ShowCurrentPasswordError ? "input regular" : "input regular error"}
                            value={currentPassword}
                            onChange={(e: any) => handleCurrentPassword(e)} 
                            data-testid='current password'
                        />
                        {ShowCurrentPasswordError && <span className="error-text semibold">Current password is incorrect</span> }
                    </div>
                    <div className="bottom-border" />
                    <div className="input-wrap">
                        <div className="label-wrap">
                            <label htmlFor="new-password" className="label-text small">New password</label>
                            <div className="right-side" onClick={() => setShowNew(!showNew)}>
                                <span className="blue-text small">{showNew ? "hide" : "show"}</span>
                                <img className="eye-image" src={blueEye} alt="blue eye" />
                            </div>
                        </div>
                        <input 
                            id="new-password" 
                            type={showNew ? "text" : "password"}
                            className={showRetypeError ? "input regular error" : "input regular"}
                            value={newPassword} 
                            onChange={(e: any) => handleNewPassword(e)}
                            data-testid='new password'
                        />
                        <div className="security-box-wrap">
                            <div className={checkTotalPassed() > 0 ? "box green" : "box"} />
                            <div className={checkTotalPassed() > 1 ? "box green" : "box"} />
                            <div className={checkTotalPassed() > 2 ? "box green" : "box"} />
                            <div className={checkTotalPassed() > 3 ? "box green" : "box"} />
                        </div>
                        {checkTotalPassed() === 0 && <div className="x-small">&nbsp;</div>}
                        {checkTotalPassed() === 1 && <span className="strength-check x-small red">Weak</span>}
                        {checkTotalPassed() === 2 && <span className="strength-check x-small yellow">Fair</span>}
                        {checkTotalPassed() === 3 && <span className="strength-check x-small green">Good</span>}
                        {checkTotalPassed() === 4 && <span className="strength-check x-small green">Strong</span>}
                    </div>
                    <div className="security-check-stack">
                        <div className="security-row">
                            {lettersPassed ?
                                <img src={greenCheckmark} alt="green checkmark circle" />
                                :
                                <img src={redCircleX} alt="red x" />
                            }
                            <span className={lettersPassed ? "pass-font" : "failed-font"}>At least one uppercase letter and one lowercase letter</span>
                        </div>
                        <div className="security-row">
                            {numberPassed ?
                                <img src={greenCheckmark} alt="green checkmark circle" />
                                :
                                <img src={redCircleX} alt="red x" />
                            }
                            <span className={numberPassed ? "pass-font" : "failed-font"}>At least one number</span>
                        </div>
                        <div className="security-row">
                            {specialPassed ?
                                <img src={greenCheckmark} alt="green checkmark circle" />
                                :
                                <img src={redCircleX} alt="red x" />
                            }
                            <span className={specialPassed ? "pass-font" : "failed-font"}>At least one special character</span>
                        </div>
                        <div className="security-row">
                            {lengthPassed ?
                                <img src={greenCheckmark} alt="green checkmark circle" />
                                :
                                <img src={redCircleX} alt="red x" />
                            }
                            <span className={lengthPassed ? "pass-font" : "failed-font"}>At least eight characters</span>
                        </div>
                    </div>
                    <div className="input-wrap">
                        <div className="label-wrap">
                            <label htmlFor="repeat-password" className="label-text small">Re-enter new password</label>
                            <div className="right-side" onClick={() => setShowRetyped(!showRetyped)}>
                                <span className="blue-text small">{showRetyped ? "hide" : "show"}</span>
                                <img className="eye-image" src={blueEye} alt="blue eye" />
                            </div>
                        </div>
                        <input
                             id="repeat-password" 
                             type={showRetyped ? "text" : "password"}
                             className={showRetypeError ? "input regular error" : "input regular"} 
                             value={retypedPassword}
                             onChange={(e: any) => handleRetypedPassword(e)}
                             onBlur={() => handleRetypedOnBlur()}
                             data-testid='repeat password'
                        />
                        {showRetypeError && <span className="error-text smallBoldButton">Passwords must match</span>}
                    </div>
                    {windowState !== "Mobile" ?
                        <>
                        <div className="bottom-border" />
                        <div className="button-wrap">
                            <button className="Button btn-secondary" onClick={() => {resetAllValues(); setChangePassword(false)}}>Cancel</button>
                            <Button 
                                title="Save"
                                isDisabled={!enableSave}
                                className={enableSave ? "Button btn-primary" : "Button btn-primary inactive"}
                                id={TIMER_IDS.USER_PASSWORD}
                                onClick={() => handleSave()}
                            />
                        </div>
                        </>
                        :
                        <Button 
                            title="Save"
                            isDisabled={!enableSave}
                            className={enableSave ? "Button btn-primary save-mobile" : "Button btn-primary inactive save-mobile"}
                            id={TIMER_IDS.USER_PASSWORD}
                            onClick={() => handleSave()}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default ChangePassword