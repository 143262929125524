import { IAddCharityRequiredInformationProps } from './interfaces/IAddCharityRequiredInformationProps';
import { Button } from '../../../../Button/Button';
import { useAddCharityRequired } from '../../../../../useHooks/componentHooks/useAddBeneficiary/useAddCharity/useAddChairtyRequired';
import { useWindowState } from '../../../../../Context/AccountContext/useWindowState';
import RXInput from '../../../../RXInput/RXInput';
// import { useLoquateOnForm } from '../../../../../shared/LoquateAutoComplete/LoquateAutoComplete';
import { useBriteVerifyOnForm } from '../../../../../shared/BriteVerify/BriteVerify';
import arrowLeftBlue from '../../../../../assets/arrowLeftBlue.svg'


/**
 * First step for adding a charity
 */
export const AddCharityRequiredInformation = (
  props: IAddCharityRequiredInformationProps
) => {

  const { windowState } = useWindowState();
  const { newBeneficiary, setNewBeneficiary, handleOnCancel, handleOnFinish, form, handleOnNext, setCurrentPage, selectedAccount, dispatchForm } = props;
  const {
    handleOnSubmit,
    formFilled,
  } = useAddCharityRequired(setNewBeneficiary, newBeneficiary, handleOnFinish, form);

  // const { onLoquateSelection, isAddressValid } = useLoquateOnForm(form, dispatchForm)
  const { isEmailValid } = useBriteVerifyOnForm(form, dispatchForm)
  
  const handleValidation = async () => {

    try {
      // await isAddressValid();
      await isEmailValid();
      handleOnSubmit();
      handleOnNext();
    }
    catch (err) {

    }

  }

  const DIV_CLASSNAME = windowState !== "Mobile" ? "row" : "row mobile"

  return (
    <div className="add-charityRequired-information">
      <div className="form">
        <div className="title-lockup">
          <div className="pre-header"><div className="content">Add Beneficiary</div><div className="step-count">{selectedAccount.beneficiaries.length > 0 ? "Step 2 of 4" : "Step 2 of 3"}</div></div>
          <h2>Charity or Non-Profit</h2>
        </div>
        <div className="grey-line" />

        <div className={DIV_CLASSNAME}>
          <RXInput control={form.controls.charityName} className="third" />
          <RXInput control={form.controls.tin} className="quarter" />
        </div>

        <div className={DIV_CLASSNAME}>
          <RXInput control={form.controls.address1} className="half" />
          <RXInput control={form.controls.address2} className="half" />
        </div>

        <div className={DIV_CLASSNAME}>
          <RXInput control={form.controls.city} className="third" />
          <RXInput control={form.controls.state} className="third" />
          <RXInput control={form.controls.zipCode} className="third" />
        </div>

        <h3>Additional Information</h3>
        <div className={DIV_CLASSNAME}>
          <RXInput control={form.controls.phoneNumber} className="quarter" />
          <RXInput control={form.controls.email} className="quarter" />
        </div>
      </div>

      {
        windowState !== "Mobile" ?
          <div className="control-bar">

            <div className="btn-back" onClick={() => { setCurrentPage(0); form.resetForm(); dispatchForm(); }}>
              <img src={arrowLeftBlue} alt={'back arrow'} />
              <span className="text regular blue">Back</span>
            </div>

            <div className="btn-group">
              <Button
                className="Button btn-secondary"
                onClick={() => handleOnCancel()}
                title="Cancel"
              />
              <Button
                className={
                  formFilled
                    ? 'Button btn-primary'
                    : 'Button btn-primary inactive'
                }
                onClick={() => {
                  handleValidation();
                }}
                title="Continue"
                isDisabled={!formFilled}
              />
            </div>
          </div>
          :
          <Button
            className={formFilled ? "Button btn-primary" : "Button btn-primary inactive"}
            onClick={() => { handleValidation() }}
            title="Continue"
            isDisabled={!formFilled}
            sx={{ width: "100%", marginBottom: "24px" }}
          />
      }
    </div >
  );
};
