import { useDocumentsLandingPage } from '../../../useHooks/componentHooks/useDocumentsLandingPage/useDocumentsLandingPage'
import DesktopAccountsDropdown from './components/DesktopAccountsDropdown/DesktopAccountsDropdown'
import DesktopDocumentCenterHeader from './components/DesktopDocumentCenterHeader/DesktopDocumentCenterHeader'
import './DesktopDocumentsLandingPage.scss'
import { IDesktopDocumentsLandingPageProps } from './interfaces/IDesktopDocumentsLandingPageProps'
import DesktopDocumentAccountStatements from '../../../components/DocumentsAccountStatements/DesktopDocumentAccountStatements/DesktopDocumentAccountStatements'
import DesktopDocumentTaxDocuments from '../../../components/DocumentTaxDocuments/DesktopDocumentTaxDocuments/DesktopDocumentTaxDocuments'
import DesktopDocumentAccountAgreements from '../../../components/DocumentAccountAgreements/DesktopDocumentAccountAgreements/DesktopDocumentAccountAgreements'
import DesktopDocumentAccountNotices from '../../../components/DocumentAccountNotices/DesktopDocumentAccountNotices/DesktopDocumentAccountNotices'
import FilterBox from './components/DesktopDateSelector/FilterBox'

/**
 * The component displays the landing page for the desktop version of the document center
 */
const DesktopDocumentsLandingPage = (props: IDesktopDocumentsLandingPageProps) => {
    const { selectedPage, setSelectedPage, availablePages, dateSelections, selectedDate, setSelectedDate, selectedAccount, filteredAccounts } = props
    const { accountInformation, isAccountInformationLoaded } = useDocumentsLandingPage()

    return(
        <div className="desktop-documents-landing-wrapper">
            <div className="centering-wrap">
                {isAccountInformationLoaded !== "Loading" ?
                    <span className="header-title h2">Document center</span>
                    :
                    <div className="loading-box-title-wrap">
                        <div className="loading-box-title" />
                    </div>
                }
            </div>
            <div className="documet-center-box-wrap">
                <div className="document-center-box">
                    {isAccountInformationLoaded !== "Loading" ?
                        <DesktopDocumentCenterHeader 
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                            availablePages={availablePages}                    
                        />
                        :
                        <div className="loading-box-header-wrapper">
                            <div className="loading-box small"/>
                            <div className="loading-box medium"/>
                            <div className="loading-box small"/>
                            <div className="loading-box large"/>
                        </div>
                    }
                </div>
            </div>
            {isAccountInformationLoaded !== "Loading" ?
                <div className="document-card-page-wrap">
                    <div className="document-card-wrap">
                        {selectedPage !== "Tax documents" &&
                            <div className="filter-row">
                                <DesktopAccountsDropdown selectedAccount={selectedAccount}/>
                                {(selectedPage === "Statements" || selectedPage === "") &&
                                    <div className="filter-box">
                                        <span className="date-text small">Time period</span>
                                        <FilterBox filterSelections={dateSelections} selectedFilter={selectedDate} setSelectedFilter={setSelectedDate} displayMessage="Date Period" />
                                    </div>
                                }
                            </div>
                        }
                        {(selectedPage === "Statements" || selectedPage === "") &&
                            <DesktopDocumentAccountStatements selectedAccount={selectedAccount} selectedDate={selectedDate} filteredAccounts={filteredAccounts} />
                        }
                        {selectedPage === "Tax documents" &&
                            <DesktopDocumentTaxDocuments selectedAccount={accountInformation[0]} />
                        }
                        {selectedPage === "Agreements" &&
                            <DesktopDocumentAccountAgreements selectedAccount={selectedAccount} />
                        }
                        {selectedPage === "Notices & other documents" &&
                            <DesktopDocumentAccountNotices selectedAccount={selectedAccount}/>
                        }
                    </div>
                </div>
                :
                <div className="card-loading-box-page-wrap">
                    <div className="card-loading-box" />
                </div>
            }
        </div>
    )
}

export default DesktopDocumentsLandingPage