import { IMobileFilterProps } from './interfaces/IMobileFilterProps'
import './MobileFilter.scss'
import blueCheckmark from '../../../../../assets/blueCheckmark.svg'

/**Displays a filter used by the mobile version staements page, it is used to filter by year */
const MobileFilter = (props: IMobileFilterProps) => {
    const { filterYear, setFilterYear, statementYearsRef, setOpenFilterMenu, tempSelectedYear, setTempSelectedYear } = props

    return(
        <div className="mobile-statement-filter-wrap">
            <div className="header-row">
                <span className="blue-text mobileSmallSemibold" onClick={() => {setOpenFilterMenu(false); setTempSelectedYear(filterYear)}}>Back</span>
                <span className="date-text semiboldRegular">Date Range</span>
                <span className="blue-text mobileSmallSemibold" onClick={() => {setFilterYear(tempSelectedYear); setOpenFilterMenu(false)}}>Save</span>
            </div>
            {statementYearsRef.current.map((year: string, index: number) => {
                return(
                    <div key={index}>
                        <div className="row-wrapper" onClick={() => setTempSelectedYear(year)}>
                            <span className={year === filterYear ? "year-text blue regular" : "year-text regular"} >{year}</span>
                            {tempSelectedYear === year && <img className="checkmark-symbol" src={blueCheckmark} alt="checkmark" />}
                        </div>
                        <div className="border-line" />
                    </div>
                )
            })}
        </div>
    )
}

export default MobileFilter