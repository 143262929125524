import { useEffect, useRef, useState } from "react"
import { IAddress } from "../../../api/User/interfaces"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { IFormContext, TControlDispatch } from "../../../Form/useForm/useForm"
import { addUserAddress, removeAddressSetIsMailing, setCheckInternalToken, setOTPPassed, updateMailingAddress, updateUserAddress} from "../../../slices/userInformation/userInformationSlice"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { deleteAddress_API, patchAddress_API, postAddress_API } from "../../../api/User/address"
import { useUserProfileContext } from "../../../Pages/UserProfile/Context/useUserProfileContext"

/**custom hook is used for the modify address modal component */
export const useModifyAddressModal = (
        form: IFormContext, 
        index: number, 
        setOpenEditPage: any, 
        purpose: "Remove" | "Edit" | "Delete" | "Add" | "", 
        setOpenMailingModal: React.Dispatch<React.SetStateAction<boolean>>,
        dispatchForm: TControlDispatch   
    ) => {
    const { windowState } = useWindowState()
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const dispatch = useAppDispatch()
    const [enableConfirm, setEnableConfirm] = useState<boolean>((purpose === "Edit" || purpose === "Add") ? true : false)
    const [newMailingAddressIndex, setNewMailingAddressIndex] = useState<number | undefined>(undefined)
    const [alignBorder, setAlignBorder] = useState<"Top" | "Bottom">("Bottom")
    const handleSaveClickedRef = useRef<boolean>(false)
    const OTPPassed = useAppSelector((state: RootState) => state.userInformation.OTPPassed)
    const debounceRef = useRef<boolean>(false)
    const { setToastMessage, resetToast, type, message } = useToastMessage()
    const apiStatusRef = useRef<true | false | null>(null)
    const { setFormFilled } = useUserProfileContext()

    useEffect(() => {
        if(purpose === "Edit" || purpose === "Add"){
            setEnableConfirm(true)
        }
    }, [purpose])

    /**Handles the radio button onclick */
    const handleRadioOnClick = (arrayIndex: number) => {
        setNewMailingAddressIndex(arrayIndex)
        setEnableConfirm(true)
    }

    /**useEffect monitors the OTP, if successful the service calls function will be called */
    useEffect(() => {
        if(OTPPassed && handleSaveClickedRef.current){
            handleServiceCalls()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPPassed])

    /**handles the multiple address service calls when dealing with a mailing address */
    const handleServiceCalls = async () => {
        apiStatusRef.current = true
        handleSaveClickedRef.current = false
        let selectedAddress = {
            id: purpose === "Add" ? crypto.getRandomValues(new Uint32Array(5)).toString() : userInformation.contactInformation.addresses[index].id,
            addressLine1: form.controls.addressLine1.value,
            addressLine2: form.controls.addressLine2.value || "",
            city: form.controls.city.value,
            state: form.controls.state.value,
            zipCode: form.controls.zipCode.value,
            isMailing: (purpose === "Edit" || purpose === "Add") ? true : false,
            isResidential: true,
            addressType: "Residential",
            country: "US"
        }
        if(purpose !== "Delete"){
            if(purpose === "Remove"){
                selectedAddress.isMailing = false
                const newMailingAddress = {
                    id: userInformation.contactInformation.addresses[newMailingAddressIndex as number].id,
                    addressLine1: userInformation.contactInformation.addresses[newMailingAddressIndex as number].addressLine1,
                    addressLine2: userInformation.contactInformation.addresses[newMailingAddressIndex as number].addressLine2 || "",
                    city: userInformation.contactInformation.addresses[newMailingAddressIndex as number].city,
                    state: userInformation.contactInformation.addresses[newMailingAddressIndex as number].state,
                    zipCode: userInformation.contactInformation.addresses[newMailingAddressIndex as number].zipCode,
                    isMailing: true,
                    isResidential: true,
                    addressType: "Residential",
                    country: "US"    
                }    
                try{
                    await patchAddress_API(newMailingAddress)
                    try{
                        await patchAddress_API(selectedAddress)
                        const payload = {
                            oldMailingIndex: index,
                            newMailingIndex: newMailingAddressIndex,
                        }
                        dispatch(updateMailingAddress(payload))
                        const payload2 = {
                            arrayIndex: index,
                            address: selectedAddress
                        }
                        dispatch(updateUserAddress(payload2))
                        setToastMessage("SUCCESS", "Address changed")
                        form.resetForm()
                        dispatchForm()
                    }
                    catch{
                        console.log("UNABLE TO REMOVE THE OLD MAILING ADDRESS FLAG")
                        setToastMessage("ERROR", "Unable to save changes. Please try again later")
                        resetToast()        
                    }
                }
                catch{
                    console.log("UNALBE TO CHANGE THE ADDRESS MAILING FLAG")
                    setToastMessage("ERROR", "Unable to save changes. Please try again later")
                    resetToast()    
                }
            }
            else if(purpose === "Edit"){
                try{
                    await patchAddress_API(selectedAddress)
                    const oldMailingIndex = userInformation.contactInformation.addresses.findIndex((address: IAddress) => address.isMailing === true)
                    const payload = {
                        arrayIndex: index,
                        address: selectedAddress
                    }
                    dispatch(updateUserAddress(payload))
                    const payload2 = {
                        oldMailingIndex: oldMailingIndex >= 0 ? oldMailingIndex : 0,
                        newMailingIndex: index
                    }
                    dispatch(updateMailingAddress(payload2))
                    setToastMessage("SUCCESS", "Address changed")
                    form.resetForm()
                    dispatchForm()
                }
                catch{
                    console.log("UNABLE TO UPDATE THE ADDRESS")
                    setToastMessage("ERROR", "Unable to save changes. Please try again later")
                    resetToast()
                }
            }
            else if(purpose === "Add"){
                try{
                    const response = await postAddress_API(selectedAddress)
                    const oldMailingIndex = userInformation.contactInformation.addresses.findIndex((address: IAddress) => address.isMailing === true)
                    selectedAddress.id = response.data.id
                    dispatch(addUserAddress(selectedAddress))
                    const payload = {oldMailingIndex: oldMailingIndex,
                                     newMailingIndex: userInformation.contactInformation.addresses.length
                                    }
                    dispatch(updateMailingAddress(payload))
                    setToastMessage("SUCCESS", "Address added")
                    form.resetForm()
                    dispatchForm()
                }
                catch{
                    console.log("UNABLE TO ADD THE NEW ADDRESS")
                    setToastMessage("ERROR", "Unable to save changes. Please try again later")
                    resetToast()
                }
            }
        }
        else{
            //need to try to delete, and then update with patch to change the mailing flag
            const newMailingAddress = {
                id: userInformation.contactInformation.addresses[newMailingAddressIndex as number].id,
                addressLine1: userInformation.contactInformation.addresses[newMailingAddressIndex as number].addressLine1,
                addressLine2: userInformation.contactInformation.addresses[newMailingAddressIndex as number].addressLine2 || "",
                city: userInformation.contactInformation.addresses[newMailingAddressIndex as number].city,
                state: userInformation.contactInformation.addresses[newMailingAddressIndex as number].state,
                zipCode: userInformation.contactInformation.addresses[newMailingAddressIndex as number].zipCode,
                isMailing: true,
                isResidential: true,
                addressType: "Residential",
                country: "US"    
            }
            try{
                await patchAddress_API(newMailingAddress)
                try{
                    await deleteAddress_API(selectedAddress.id)
                    const payload = {oldMailingIndex: index,
                                     newMailingIndex: newMailingAddressIndex
                                    }
                    dispatch(removeAddressSetIsMailing(payload))
                    setToastMessage("SUCCESS", "Address removed")
                    form.resetForm()
                    dispatchForm()
                }
                catch{
                    console.log("UNALBE TO REMOVE THE ADDRESS")
                    setToastMessage("ERROR", "Unable to save changes. Please try again later")
                    resetToast()
                }    
            }
            catch{
                console.log("UNALBE TO CHANGE THE ADDRESS MAILING FLAG")
                setToastMessage("ERROR", "Unable to save changes. Please try again later")
                resetToast()
            }
        }
        form.resetForm()
        dispatchForm()
        setFormFilled(false)
        apiStatusRef.current = false
        dispatch(setOTPPassed(false))
        setOpenMailingModal(false)
        setOpenEditPage("")
    }

    /**Handles the on confirm event */
    const handleOnConfirm = async () => {
        if(!debounceRef.current){
            debounceRef.current = true
            handleSaveClickedRef.current = true
            dispatch(setOTPPassed(false))
            dispatch(setCheckInternalToken(true))
            setTimeout(() => {
                debounceRef.current = false
            }, 5000)
            return new Promise((resolve) => {
                let interval: any
                interval = setInterval(() => {
                    if(apiStatusRef.current === false){
                        apiStatusRef.current = null
                        resolve(true)
                        clearInterval(interval)
                    }
                }, 100)    
            })
        }
    }

    /**watches scrolling on the inner modal and adjust the styling of the display box as needed*/
    const handleOnScroll = (e: any) => {
        if(e.currentTarget.scrollTop > 50){
            setAlignBorder("Top")
        }
        else{
            setAlignBorder("Bottom")
        }
    }

    /**Gets the old mailing address for case of add with mailing checkbox checked */
    const getOldMailingIndexData = (field: "addressLine1" | "addressLine2" | "city" | "state" | "zipCode") => {
        const oldMailingIndex = userInformation.contactInformation.addresses.findIndex((address: IAddress) => address.isMailing === true)
        switch(field){
            case "addressLine1":
                return userInformation.contactInformation.addresses[oldMailingIndex]?.addressLine1
            case "addressLine2":
                return userInformation.contactInformation.addresses[oldMailingIndex]?.addressLine2
            case "city":
                return userInformation.contactInformation.addresses[oldMailingIndex]?.city
            case "state":
                return userInformation.contactInformation.addresses[oldMailingIndex]?.state
            case "zipCode":
                return userInformation.contactInformation.addresses[oldMailingIndex]?.zipCode
        }
    }

    /**Lock the scrollbar on mount, then unlock on unmount */
    useEffect(() => {
        document.body.style.overflow = "hidden"
        return () => {document.body.style.overflow = "auto"}
    }, [])

    return { userInformation, enableConfirm, handleRadioOnClick, handleOnConfirm, alignBorder, handleOnScroll, windowState, 
             getOldMailingIndexData, newMailingAddressIndex, type, message, setFormFilled}
}