import { useEffect, useState } from 'react';
import { IBeneficiary } from '../../../../api/getBeneficiaries';
import { useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { IFormContext } from '../../../../Form/useForm/useForm';
import { checkControlValues } from '../../../../utils/IsFormFilled';

/**
 * useHook for adding personal infromation about a beneficiary
 */
export const useAddPersonRequired = (
  setNewBeneficiary: React.Dispatch<React.SetStateAction<IBeneficiary>>,
  handleOnFinish: (updatedBeneficiary: IBeneficiary) => void,
  newBeneficiary: IBeneficiary,
  form: IFormContext
) => {
  const [formFilled, setFormFilled] = useState(false);
  const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
  

  /**
   * When page is rendered, there's a check to see if the form is already filled
   */
  useEffect(() => {
    setFormFilled(checkControlValues(form.controls, "Person"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.controls]);

  /**
   * Sets the new beneficiary to what the user enetered in the form, then goes to the enxt page
   */
  const handleOnSubmit = () => {
    const inputValues = form.controls
    setNewBeneficiary({
      ...newBeneficiary,
      name: inputValues.firstName.value + " " + inputValues.lastName.value,
      firstName: inputValues.firstName.value,
      middleName: inputValues.middleName.value,
      lastName: inputValues.lastName.value,
      tin: inputValues.ssn.value,
      ssn: inputValues.ssn.value,
      dateOfBirth: inputValues.dateOfBirth.value,
      relationship: inputValues.relationship.value,
      address1: inputValues.address1.value,
      address2: inputValues.address2.value,
      city: inputValues.city.value,
      state: inputValues.state.value,
      zipCode: inputValues.zipCode.value,
      phoneNumber: inputValues.phoneNumber.value,
      email: inputValues.email.value,
      type: "Person",
      percentage: selectedAccount.beneficiaries.length > 0 ? 0 : 100
    });
  };

  return {
    handleOnSubmit,
    setFormFilled,
    formFilled
  }
};
