/* eslint-disable @typescript-eslint/no-unused-vars */
import { IBeneficiary } from '../../../../api/getBeneficiaries';
import { COLOR_ARRAY } from '../../../../utils/globalVariables';
import './DesktopBeneficiarySummary.scss';
import { PieChart } from '../../../PieChart/PieChart';
import { useBeneficiarySummary } from '../../../../useHooks/componentHooks/useBeneficiarySummary/useBeneficiarySummary';
import { IDesktopBeneficiarySummaryProps } from './interfaces/IDesktopBeneficiarySummaryProps';
// import { AlertMessage } from '../../../AlertMessage/AlertMessage';
import { SetupBeneficies } from '../../../SetupBeneficies/SetupBeneficies';
import warning from '../../../../assets/warning.svg'
import elipses from '../../../../assets/elipses.svg'
import bluePencil from '../../../../assets/bluePencil.svg'
import redTrashCan from '../../../../assets/redTrashCan.svg'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage';

/**
 * Displays the beneficiaries and Pie Chart from the beneficiaries landing page desktop version.
 * From here beneficiaries can be added or edited.
 */
const DesktopBeneficiarySummary = (props: IDesktopBeneficiarySummaryProps) => {
  const {
    editBeneficiary,
    setEditBeneficiary,
    setEditProfile,
    showAlertMessage,
    setShowAlertMessage,
    setAddBeneficiary,
    handleOnClick,
    setDeleteBeneficiary,
    form,
    dispatchForm
  } = props;
  const { selectedAccount, isBeneficiariesLoaded, openMenu, setOpenMenu, handleMenuOnClick, message, type } = useBeneficiarySummary(showAlertMessage, setShowAlertMessage);

  return (
    <>
      {isBeneficiariesLoaded !== "Loading" ?
        <>
          {isBeneficiariesLoaded === "Success" ?
            <div className="card-wrapper beneficiary-summary">

              {selectedAccount.beneficiaries.length ? (
                <>
                  <div className="container">
                    <div className="desktop">
                      <div className="content-wrapper">
                        <div className="title-lockup with-buttons">
                          <div className="title">
                            <h1>Beneficiaries</h1>
                            <p>Add or manage beneficiaries and allocation amounts.</p>
                          </div>
                          <div className="btn-group">

                            {selectedAccount.beneficiaries.length > 1 ?
                              < button
                                className="Button btn-secondary"
                                onClick={() => setEditBeneficiary(true)}
                              >
                                Adjust allocations
                              </button>
                              : null}
                            <button
                              className={selectedAccount.beneficiaries.length < 10 ? "Button btn-primary" : "Button btn-primary inactive"}
                              onClick={() => { setAddBeneficiary(true); form.resetForm(); form.enableAllInputs(); dispatchForm() }}
                              disabled={selectedAccount.beneficiaries.length >= 10}
                            >
                              Add beneficiary
                            </button>
                          </div>
                        </div>
                        <div className="grey-line"></div>
                        <div className="content">
                          <div className="Beneficiary-Details">
                            <div className="Chart-Header-Line">
                              <span className="Header-Text small">Beneficiary</span>
                              <span className="Header-Text Allocation small">Allocation</span>
                            </div>

                            {selectedAccount.beneficiaries.map(
                              (beneficiary: IBeneficiary, index: number) => {

                                return (
                                  <div key={index} className="Display-Beneficiaries">
                                    <div className="Row-Wrapper">
                                      <div className="Name-Percentage-Arrow-Wrap">
                                        <div
                                          className="Dot"
                                          style={{ backgroundColor: COLOR_ARRAY[index] }}
                                        />
                                        {beneficiary.type === 'Person' && (
                                          <div className="Member-Name regular cls_mask">{`${beneficiary?.name}`}<span className="Person-Text">{beneficiary?.type}</span></div>
                                        )}
                                        {(beneficiary.type === 'Charity' || beneficiary.type === "Non Profit") && (
                                          <div className="Member-Name regular cls_mask">{`${beneficiary?.name}`}<span className="Person-Text">{beneficiary?.type}</span></div>
                                        )}
                                        {beneficiary.type === 'Trust' && (
                                          <div className="Member-Name regular cls_mask">{`${beneficiary?.name}`}<span className="Person-Text">{beneficiary?.type}</span></div>
                                        )}
                                        <div className="Percentage-Arrow-Wrap">
                                          <span className="Member-Percentage">
                                            {beneficiary.percentage}%
                                          </span>
                                          <img
                                            className="Arrow"
                                            src={elipses}
                                            alt="Open"
                                            onClick={(e) => {
                                              if (!beneficiary.hasOwnProperty('isDeleted')) {
                                                return
                                              }
                                              setOpenMenu(index)
                                              handleMenuOnClick(e)
                                              handleOnClick(beneficiary)
                                            }}
                                          />
                                          {openMenu === index &&
                                            <div className="menu-wrap">
                                              <div className="menu-row-wrap" onClick={() => setEditProfile(true)}>
                                                <img src={bluePencil} alt="edit profile" />
                                                <span className="edit-profile-text regular">Edit Profile</span>
                                              </div>
                                              <div className="menu-row-wrap" onClick={() => setDeleteBeneficiary(true)}>
                                                <img src={redTrashCan} alt="delete" />
                                                <span className="delete-text regular">Remove</span>
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="Pie-Chart">
                            {selectedAccount.beneficiaries.length > 1 && (
                              <div className="Pie-Size">
                                <PieChart beneficiaries={selectedAccount.beneficiaries} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <SetupBeneficies
                  setAddBeneficiaries={setAddBeneficiary}
                  form={form}
                  dispatchForm={dispatchForm}
                />
              )}
            </div>
            :
            <div className="beneficiary-page-api-error-state-wrapper">
              <div className="beneficary-page-api-error-state">
                <h1>Beneficiaries</h1>
                <span className="sub-text regular">Add or manage beneficiaries and allocation amounts.</span>
                  <div className="message-wrap">
                    <img className="error-image" src={warning} alt="error" />
                    <span className="title-text h4">Unable to load data</span>
                    <span className="sub-title-text regular">Unable to load data. Please wait a moment and try again</span>
                  </div>
              </div >
            </div>
          }
        </>
        :
        <div className="beneficiary-summary-loading-state">
          <div className='container'>
            {/* <div className="top-line" /> */}
            {/* <div className="bottom-box" /> */}
            <div className="loading-box" />
          </div>
        </div>
      }
    </>
  );
};

export default DesktopBeneficiarySummary;
