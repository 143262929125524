import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { useWindowState } from "../../Context/AccountContext/useWindowState"
import { getAccountDetailsAsync } from '../../slices/selectedAccountSlice/selectedAccountSlice'
import { IProduct } from "../../api/getAccountInformation"
import { useHistory } from "react-router-dom"

/** A custom use hook for the Grace Period page */
export const useGracePeriod = () => {
    const history = useHistory()
    const { windowState } = useWindowState()
    const [changeCD, setChangeCD] = useState<boolean>(false)
    const [fundTransfer, setFundTransfer] = useState<boolean>(false)
    const [closeAccount, setCloseAccount] = useState<boolean>(false)
    const [chosenTerm, setChosenTerm] = useState<IProduct>({code: "", name: "", accountType: "", group: "", subType: "", term: "", apy: "", preferedRate: false})
    const [showTermChange, setShowTermChange] = useState<boolean>(false)
    const [transferType, setTransferType] = useState<"Add" | "Withdraw" | "">("")
    const [firstStepCompleted, setFirstStepCompleted] = useState<boolean>(false)
    const [openCancelFunds, setOpenCancelFunds] = useState<boolean>(false)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()
    const [transferAccount, setTransferAccount] = useState<{id: string, title: string, lastFour: string, accountType: "external" | "internal" | ""}>({
        id: "",
        title: "",
        lastFour: "",
        accountType: ""
    }) 
    const [showPhoneNumber, setShowPhoneNumber] = useState<boolean>(false)


    /**Repopulate the selectedAccount on a page refresh */
    useEffect(() => {
        if(selectedAccount.id === "" && accountInformation[0].id !== ""){
            const adjustedUrl = window.location.pathname.split('/')[2]
            const index = accountInformation.findIndex((account: any) => adjustedUrl === account.id)
            if(index >= 0){
                const account = accountInformation[index]
                const payload = {arrayIndex: index, account: account}
                dispatch(getAccountDetailsAsync(payload))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, accountInformation])

    // push back to account details if the user enters grace period by url but the account is not in grace period
    useEffect(() => {
        if(selectedAccount.id !== "" && !selectedAccount.isGracePeriod){
            history.push(`/accounts/${selectedAccount.id}`)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount])

    return { windowState, changeCD, setChangeCD, fundTransfer, setFundTransfer, closeAccount, setCloseAccount, chosenTerm, setChosenTerm,
             showTermChange, setShowTermChange, transferType, setTransferType, firstStepCompleted, setFirstStepCompleted,
             openCancelFunds, setOpenCancelFunds, transferAccount, setTransferAccount, showPhoneNumber, setShowPhoneNumber, selectedAccount }
}