import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import { IOpenSavingsAccountProps } from './interfaces/IOpenSavingsAccountProps'
import './OpenSavingsAccount.scss'
import openSavings from '../../../../assets/openSavings.svg'
import blueCheckmark from '../../../../assets/blueCheckmark.svg'
import { useOpenSavingsAccount } from '../../../../useHooks/componentHooks/useOpenSavingsAccount/useOpenSavingsAccount'
import information from '../../../../assets/information.svg'
import { getSAMLURL } from '../../../../shared/config/getSAMLUrl'

/**Renders the open a new savings account page on the early withdrawal request when a Savings account is not present */
const OpenSavingsAccount = (props: IOpenSavingsAccountProps) => {
    const { windowState } = useWindowState()
    const { setConfirmEarlyWithdrawal, setFoundSavings, setCloseAccount } = props
    const { setOpenSavings, selectedAccount, showTooltip, setShowTooltip } = useOpenSavingsAccount()

    return (
        <>
        {windowState === "Mobile" &&
            <div className="mobile-open-savings-header">
                <span className="header-text regular" onClick={() => {if(setCloseAccount){setCloseAccount(false)}}}>Cancel</span>
            </div>
        }
        <div className={windowState !== "Mobile" ? "open-savings-breakpoint-wrap desktop" : "open-savings-breakpoint-wrap"}>
            <div className={windowState !== "Mobile" ? "open-savings-page-wrapper" : "open-savings-page-wrapper mobile"}>
                {windowState === "Mobile" ?
                    <div className="savings-header-wrapper">
                        <span className="header-text small">Close your CD</span>
                        <span className="sub-header-text h3">Would you like to transfer your CD to a new savings account?</span>
                    </div>
                    :
                    <div className="savings-header-wrapper mobile">
                        <span className="header-text small">Close your CD</span>
                        <span className="sub-header-text h4">Would you like to transfer your CD to a new savings account?</span>
                    </div>
                }
                <div className="open-savings-wrapper">
                    <div className="message-wrapper">
                        <img className="image"  src={openSavings} alt="open savings account" />
                        <span className="header-text boldLarge">Access your money instantly when you deposit it in a new Jumbo High Yield Savings account</span>
                        <div className="point-wrapper">
                            <img src={blueCheckmark} alt="checkmark" />
                            <div className="data-icon-wrap">
                                <span className={windowState !== "Mobile" ? "point-text regular" : "point-text small mobile"}>Competitive rates, plus a Loyalty Rate*.</span>
                                {windowState !== "Mobile" ?
                                    <>
                                    {showTooltip &&
                                        <div className="tooltip-wrap">
                                            <span className="tooltip-text margin small">Receive a .05% Loyalty Rate increase when you renew your CD account.</span>
                                            <span className="tooltip-text small">The Loyalty Rate increase is subject to change and may be discontinued at any time. When your CD is approaching maturity, visit our CD homepage to view the current reward rate. See the FAQs for more information.</span>
                                            <span className="triangle" />
                                        </div>
                                    }
                                    <img data-testid="open-savings-tooltip" className="tooltip-icon" src={information} alt="tooltip" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}/>
                                    </>
                                    :
                                    <img data-testid="open-savings-tooltip" className="tooltip-icon" src={information} alt="tooltip" onClick={() => setShowTooltip(true)}/>
                                }
                            </div>
                        </div>
                        <div className="point-wrapper">
                            <img src={blueCheckmark} alt="checkmark" />
                            <span className={windowState !== "Mobile" ? "point-text regular" : "point-text small mobile"}>Easy access to your funds online or in our mobile app.</span>
                        </div>
                        <div className="point-wrapper">
                            <img src={blueCheckmark} alt="checkmark" />
                            <span className={windowState !== "Mobile" ? "point-text regular" : "point-text small mobile"}>Takes just a few minutes to open an account.</span>
                        </div>
                        <div className={windowState !== "Mobile" ? "button-wrapper" : "button-wrapper mobile"}>
                            <button className={windowState !== "Mobile" ? "Button btn-primary" : "Button btn-primary mobile"} onClick={() => {setOpenSavings(true); window.open(getSAMLURL(), '_blank')}}>Open a savings account</button>
                            <button className="no-thanks-button regular" onClick={() => {setFoundSavings(true); setConfirmEarlyWithdrawal(true)}}>No thanks</button>
                        </div>
                    </div>
                </div>
                {windowState !== "Mobile" &&
                    <div className="cancel-button-wrap">
                        <button className="Button btn-secondary boldLarge" onClick={() => {if(setCloseAccount){setCloseAccount(false)}}}>Cancel</button>
                    </div>
                }
            </div>
            {(windowState === "Mobile" && showTooltip) &&
                <div data-testid="grace-savings-overlay" className="overlay">
                    <div className="tool-card">
                        <div className="header-wrap">
                            <span className="header-text semiboldRegular">Loyalty Rate Increase</span>
                            <span className="close-button regular" onClick={() => setShowTooltip(false)}>Close</span>
                        </div>
                        <span className="tool-text regular">Receive a .05% Loyalty Rate increase when you renew your CD account.</span>
                        <span className="tool-text regular">The Loyalty Rate increase is subject to change and may be discontinued at any time. When your CD is approaching maturity, visit our CD homepage to view the current reward rate. See the FAQs for more information.</span>
                    </div>
                </div>
            }
        </div>
        </>
    )
}

export default OpenSavingsAccount