// import { useLoquateOnForm, AddressInputWrapper } from '../../../../../../shared/LoquateAutoComplete/LoquateAutoComplete'
import { useEditBeneficiaryTrust } from '../../../../../../useHooks/componentHooks/useEditBeneficiaryTrust/useEditBeneficiaryTrust'
import { Button, TIMER_IDS } from '../../../../../Button/Button'
import RXInput from '../../../../../RXInput/RXInput'
import './DesktopEditTrust.scss'
import { IDesktopEditTrustProps } from './interfaces/IDesktopEditTrustProps'

/**
 * The component renders the Edit Trust component for the desktop version.
 */
const DesktopEditTrust = (props: IDesktopEditTrustProps) => {
    const { editPerson, setEditPerson, setEditProfile, setEditBeneficiary, selectedBeneficiary, setDeleteBeneficiary, form, dispatchForm } = props
    const { handleOnSubmit, formFilled, } = useEditBeneficiaryTrust(setEditProfile, setEditBeneficiary, selectedBeneficiary, form)
    // const { onLoquateSelection, isAddressValid } = useLoquateOnForm(form, dispatchForm)

    const handleValidation = async () => {

        try {
            // await isAddressValid();
            await handleOnSubmit();
            return Promise.resolve(true)
        }
        catch (err) {

        }

    }

    return (
        <div className="Desktop-Beneficiary-Edit-Trust">
            <div className="edit-trust-page-wrap">
                <div className="Edit-Trust-Details">
                    <div className="Chart-Header">
                        <div className="Chart-Settings">
                            <span className="Header-Text h4">Beneficiary Settings</span>
                            <span className="Regular-Text regular">Make sure your beneficiary’s information is up to date. Choose edit to make changes. </span>
                        </div>
                        <div className="button-wrap">
                            {!editPerson ?
                                <Button className="Button btn-secondary margin boldButtonLarge" onClick={() => setDeleteBeneficiary(true)} title="Remove" />
                                :
                                <Button className="Button btn-secondary margin boldButtonLarge" onClick={() => { setEditPerson(false); setEditProfile(false) }} title="Cancel" />
                            }
                            {!editPerson ?
                                <Button className="Button btn-primary boldButtonLarge" onClick={() => { setEditPerson(true); form.enableAllInputs(); dispatchForm() }} title="Edit" />
                                :
                                <Button 
                                    className={formFilled ? "Button btn-primary boldButtonLarge" : "Button btn-primary inactive boldButtonlarge"} 
                                    onClick={handleValidation} 
                                    title="Save" 
                                    isDisabled={!formFilled} 
                                    id={TIMER_IDS.BENEFICIARY_EDIT}
                                />
                            }
                        </div>
                    </div>
                    <div className="form-wrapper">
                        <span className="Trust-Info-Header boldLarge">Trust Information</span>
                        <div className="inputs-wrapper">
                            <RXInput control={form.controls.trustName} className="half" />
                            <RXInput control={form.controls.trustEstablishedDate} className="quarter" />
                            <RXInput control={form.controls.tin} className="quarter"/>
                        </div>
                        <div className="inputs-wrapper">
                            <RXInput control={form.controls.address1} className="half" />
                            <RXInput control={form.controls.address2} className="half" />
                        </div>
                        <div className="inputs-wrapper">
                            <RXInput control={form.controls.city} className="half" />
                            <RXInput control={form.controls.state} className="quarter" />
                            <RXInput control={form.controls.zipCode} className="quarter" />
                        </div>
                        <span className="Additional-Info-Header boldLarge">Additional Information</span>
                        <div className="inputs-wrapper">
                            <RXInput control={form.controls.phoneNumber} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DesktopEditTrust