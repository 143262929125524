import MobileDocumentsDisplayHeader from '../../../Pages/Documents/MobileDocumentsPage/components/MobileDocumentsDisplayHeader/MobileDocumentsDisplayHeader'
import { IMobileDocumentTaxDocumentsProps } from './interfaces/IMobileDocumentTaxDocumentsProps'
import './MobileDocumentTaxDocuments.scss'
import { ITaxDocuments, downloadTaxDocument } from '../../../api/getAccountInformation'
import blueDocument from '../../../assets/blueDocument.svg'
// import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import warning from '../../../assets/warning.svg'
import emptyStatements from '../../../assets/emptyStatements.svg'
import { useToastMessage } from '../../../Context/ToastContext/useToastContext'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import { downloadDocuments } from '../../../utils/downloadDocuments'
import { useRef } from 'react'

/**This component displays the tax document for the Document Center mobile version */
const MobileDocumentTaxDocuments = (props: IMobileDocumentTaxDocumentsProps) => {
    const { selectedAccount, selectedPage, setSelectedPage } = props
    const gotTaxDocuments = useAppSelector((state: RootState) => state.accountInformation.gotTaxDocuments)
    const { message, type, setToastMessage, resetToast } = useToastMessage()
    const downloadClickedRef = useRef<boolean>(false)

    /**handle the onClick event and downloads the account statement document */
    const handleTaxDocumentDownload = async (date: string) => {
        if(!downloadClickedRef.current){
            downloadClickedRef.current = true
            try{
                const response = await downloadTaxDocument(date.slice(date.length - 4, date.length))
                downloadDocuments(response.data, "", "Tax", date)
            }
            catch{
                console.log("FAILED TO DOWNLOAD THE ACCOUNT STATEMENT")
                setToastMessage("ERROR", "Unable to download document. Please try again later")
                resetToast()
            }
            downloadClickedRef.current = false
        }
    }    

    return(
        <div>
            <MobileDocumentsDisplayHeader selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            <div className="mobile-account-statements-list">
                {gotTaxDocuments !== "Loading" ?
                    <>
                    {gotTaxDocuments === "Success" ?
                        <>
                        {selectedAccount.taxDocuments.length > 0 ?
                            <>
                            {selectedAccount.taxDocuments.map((document: ITaxDocuments, index: number) => {
                                return(
                                    <div key={index} className="document-row" onClick={() => handleTaxDocumentDownload(document.date)}>
                                        <img className="download-image" src={blueDocument} alt="download" />
                                        <span className="staement-text regular" >Tax Document {document.date} - PDF</span>
                                    </div>
                                )
                            })}
                            </>
                            :
                            <div className="message-wrapper">
                                <img className="message-image" src={emptyStatements} alt="no statements" />
                                <span className="header-text margin h4">No tax documents</span>
                                <span className="sub-text regular">Future tax documents will be listed here</span>
                            </div>
                        }
                        </>
                        :
                        <div className="message-wrapper">
                            <img className="message-image" src={warning} alt="no statements" />
                            <span className="header-text margin h4">Unable to load data</span>
                            <span className="sub-text regular">Please wait a moment and try again.</span>
                        </div>
                    }
                    </>
                    :
                    <div className="loading-box" />
                }
            </div>
            <div className="toast-wrapper">
                {message !== "" && <ToastMessageDB type={type} message={message} /> }
            </div>
        </div>
    )
}

export default MobileDocumentTaxDocuments