import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import CurrentBalanceHeader from '../../../shared/digitalbank/CurrentBalanceHeader/CurrentBalanceHeader'
import CancelTransfersModal from '../components/CancelTransferModal/CancelTransfersModal'
import ChangeCD from '../components/ChangeCD/ChangeCD'
import CloseAccount from '../components/CloseAccount/CloseAccount'
import GraceConfirmTermChange from '../components/GraceConfirmTermChange/GraceConfirmTermChange'
import MobileGraceLandingPage from '../components/GraceLandingPage/MobileGraceLandingPage/MobileGraceLandingPage'
import MobileGraceTransferFunds from '../components/GraceTransferFunds/MobileGraceTransferFunds/MobileGraceTransferFunds'
import { IMoblileGracePeriodProps } from './interfaces/IMobileGracePeriodProps'
import './MobileGracePeriod.scss'

/**Renders the Grace Period landig page for the mobile version */
const MobileGracePeriod = (props: IMoblileGracePeriodProps) => {
    const { changeCD, setChangeCD, fundTransfer, setFundTransfer, closeAccount, setCloseAccount ,chosenTerm, setChosenTerm,
            showTermChange, setShowTermChange, transferType, setTransferType, firstStepCompleted, setFirstStepCompleted,
            openCancelFunds,  setOpenCancelFunds, transferAccount, setTransferAccount, showPhoneNumber, setShowPhoneNumber } = props


    return (
        <div className="mobile-grace-period-wrap">
            {(!changeCD && !fundTransfer && !showTermChange && !closeAccount) &&
            <>
                <div className="mobile-heder-wrap">
                    <AccountHeader returnMessage="Maturity Plan" setDisplayDetails={false}/>
                    <CurrentBalanceHeader />
                </div>
                <MobileGraceLandingPage 
                    setChangeCD={setChangeCD}
                    setFundTransfer={setFundTransfer}
                    setCloseAccount={setCloseAccount}
                    transferType={transferType}   
                    setOpenCancelFunds={setOpenCancelFunds}     
                    showPhoneNumber={showPhoneNumber}
                    setShowPhoneNumber={setShowPhoneNumber}    
                />
            </>
            }
            {changeCD &&
                <ChangeCD 
                    chosenTerm={chosenTerm}
                    setChosenTerm={setChosenTerm}
                    setShowTermChange={setShowTermChange}
                    setChangeCD={setChangeCD}
                />        
            }
            {showTermChange &&
                <GraceConfirmTermChange 
                    chosenTerm={chosenTerm}
                    setShowTermChange={setShowTermChange}
                    setChangeCD={setChangeCD}
                    fundTransfer={fundTransfer}
                    setFundTransfer={setFundTransfer}
                    transferType={transferType}
                    closeAccount={closeAccount}
                    setCloseAccount={setCloseAccount}
                    transferAccount={transferAccount}
                    setChosenTerm={setChosenTerm}
                />        
            }
            {(fundTransfer && !showTermChange) &&
                <MobileGraceTransferFunds 
                    transferType={transferType}
                    setTransferType={setTransferType}
                    firstStepCompleted={firstStepCompleted} 
                    setFirstStepCompleted={setFirstStepCompleted}
                    setShowTermChange={setShowTermChange}
                    setFundTransfer={setFundTransfer}
                    transferAccount={transferAccount}
                    setTransferAccount={setTransferAccount}
                />
            }
            {openCancelFunds &&
                <CancelTransfersModal 
                    setOpenCancelFunds={setOpenCancelFunds}
                />
            }
            {(closeAccount && !showTermChange) &&
                <CloseAccount 
                    setCloseAccount={setCloseAccount}
                    setShowTermChange={setShowTermChange}
                    setTransferAccount={setTransferAccount}
                    transferAccount={transferAccount}
                />
            }
        </div>
    )
}

export default MobileGracePeriod