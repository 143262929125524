import { IGetAccountInformationResponse } from '../types/getAccountInformation.types';

export const mockbasicAccountInfo: IGetAccountInformationResponse = {
  data: [
    {
      id: '123456',
      accountNumber: '1234567892000',
      accountType: 'CD',
      accountTitle: 'June Bug',
      description: 'Jumbo CD 12 Month',
      balance: 101050,
      interestRate: 1.75,
      term: '12 Months',
      maturityDate: '10/08/2023',
      routingNumber: '122402133',
      owner: [
        { name: 'Jim Harbaugh', role: 'Owner' },
        { name: 'David Duval', role: 'Co-owner' }
      ],
      interestPaid: 2801.40,
      interestPaidYTD: 2801.40,
      interestPaidPriorYear: 0,
      selectedInterestAccount: {
        id: "1",
        accountNumber: '1234567892000',
        accountType: 'Internal',
        accountTitle: 'June Bug'
      },
      accountStatements: [
        {
          id: '1',
          date: '2022-11-30T16:39:25.870+00:00'
        },
        {
          id: '2',
          date: '2021-11-30T16:39:25.870+00:00'
        },
        {
          id: '3',
          date: '2020-11-30T16:39:25.870+00:00'
        },
        {
          id: '4',
          date: '2019-11-30T16:39:25.870+00:00'
        },
        {
          id: '5',
          date: '2018-11-30T16:39:25.870+00:00'
        }
      ],
      taxDocuments: [
        {
          id: '1',
          date: '2023-12-30T16:39:25.870+00:00'
        },
        {
          id: '2',
          date: '2022-12-30T16:39:25.870+00:00'
        },
        {
          id: '3',
          date: '2021-12-30T16:39:25.870+00:00'
        }
      ],
      accountAgreements: [
        {
          id: '1',
          date: '2021-12-30T16:39:25.870+00:00',
          title:
            'Limited Electronic Disclosure and Communications Consent Agreement'
        },
        {
          id: '2',
          date: '2021-12-30T16:39:25.870+00:00',
          title: 'Deposit Agreement and Disclosures'
        }
      ],
      accountNotices: [
        {
          id: '1',
          date: '2022-12-30T16:39:25.870+00:00',
          title: 'Account Notice'
        },
        {
          id: '2',
          date: '2021-12-30T16:39:25.870+00:00',
          title: 'Account Notice'
        },
        {
          id: '3',
          date: '2021-07-30T16:39:25.870+00:00',
          title: 'Account Notice'
        }
      ],
      maturitySetting: {
        option: 'Renew',
        termLength: '12',
        changeProduct: 'Jumbo CD 12 Month',
        scheduledTransferId: ""
      },
      interestAccount: {
        accountNumber: '1234567892000'
      },
      beneficiaries: [
        {
          id: "1",
          name: "Marty Byrde",
          firstName: "Marty",
          lastName: "Byrde",
          percentage: 40,
          tin: '123456789',
          dateOfBirth: '09/27/1972',
          address1: '123 Ozarks Drive',
          city: 'Ozarks',
          state: 'MO',
          zipCode: '65721',
          relationship: 'Spouse',
          type: 'Person',
          isDeleted: false
        },
        {
          id: "2",
          name: "Wendy Byrde",
          firstName: "Wendy",
          lastName: "Byrde",
          percentage: 20,
          tin: '987654321',
          dateOfBirth: '03/15/1977',
          address1: '123 Ozarks Drive',
          city: 'Ozarks',
          state: 'MO',
          zipCode: '65721',
          relationship: 'Spouse',
          type: 'Person',
          isDeleted: false
        },
        {
          id: "3",
          name: "Charlotte Byrde",
          firstName: "Charlotte",
          lastName: "Byrde",
          percentage: 20,
          tin: '123459876',
          dateOfBirth: '08/01/1992',
          address1: '123 Ozarks Drive',
          city: 'Ozarks',
          state: 'MO',
          zipCode: '65721',
          relationship: 'Child',
          type: 'Person',
          isDeleted: false
        },
        {
          id: "4",
          name: "The Northern Trust Compnay",
          trustName: "The Northern Trust Company",
          trustEstablishedDate: "08/12/1889",
          percentage: 10,
          tin: '36-3046063',
          dateOfBirth: '02/05/1997',
          address1: '2500 Sand Hill Rd ',
          address2: 'Suite 150',
          city: 'Menlo Park',
          state: 'CA',
          zipCode: '94025',
          phoneNumber: '+1 (650) 926-9200',
          type: 'Trust',
          isDeleted: false
        },
        {
          id: "5",
          name: "Save the Children Federation",
          charityName: "Save the Children Federation",
          percentage: 10,
          tin: '06-0726487',
          address1: '501 Kings Highway East',
          address2: 'Suite 400',
          city: 'Fairfield',
          state: 'CT',
          zipCode: '06825',
          phoneNumber: '+1 (800) 728-3843',
          email: 'supportercare@savechildren.org',
          type: 'Charity',
          isDeleted: false
        }
      ],
      statements: [
        {
          id: '1',
          date: '07/21/2021'
        },
        {
          id: '2',
          date: '08/21/2021'
        },
        {
          id: '3',
          date: '09/21/2020'
        },
        {
          id: '4',
          date: '09/21/2019'
        },
        {
          id: '5',
          date: '09/21/2018'
        },
        {
          id: '6',
          date: '09/21/2017'
        },
        {
          id: '7',
          date: '09/21/2016'
        },
        {
          id: '8',
          date: '09/21/2015'
        },
        {
          id: '9',
          date: '09/21/2014'
        },
        {
          id: '10',
          date: '09/21/2013'
        },
        {
          id: '11',
          date: '09/21/2012'
        },
        {
          id: '12',
          date: '09/21/2011'
        },
      ],
      isBeneficiariesLoaded: false,
      creationDate: '2022-04-21T16:39:25.870+00:00',
      bumpActivated: false,
      isBannerAvailable: false,
      earlyWithdrawal: {
        transferAccount: "",
        transferAmount: 0,
        transferDate: "",
        transferAccountTitle: "",
        optionalAnswer: ""
      },
      earlyWithdrawalSelected: false,
      accountClosed: false,
      accountClosedDate: "",
      isGracePeriod: false,
      gracePeriodFundTransfer: { transferAccount: "", transferAmount: 0, transferType: "", transferAccountId: "", transferId: "" },
      transactions: [],
      isTransactionsLoaded: false,
      cdGroup: "",
      graceTermRenewal: {cdGroup: "", cdTerm: "", apy: "", productCode: ""},
      currentBalance: 120120,
      graceEndDate: "",
      graceStartDate: "",
      earlyWithdrawalPenalty: "",
      loyaltyRate: false,
      pendingGraceClosure: false
    },
    {
      id: '123456',
      accountNumber: '1234567893000',
      accountType: 'CD',
      accountTitle: 'Early Withdrawal CD',
      description: 'Jumbo CD 12 Month',
      balance: 0,
      interestRate: 1.75,
      term: '12 Months',
      maturityDate: '10/08/2024',
      routingNumber: '122402133',
      owner: [
        { name: 'Jim Harbaugh', role: 'Owner' },
        { name: 'Tom Brady', role: 'Co-owner' }
      ],
      interestPaid: 2801.40,
      interestPaidYTD: 2801.40,
      interestPaidPriorYear: 0,
      selectedInterestAccount: {
        id: "1",
        accountNumber: '1234567892000',
        accountType: 'Internal',
        accountTitle: 'Rainy Day Fund'
      },
      accountStatements: [
        {
          id: '1',
          date: '11/21/21'
        },
        {
          id: '2',
          date: '10/21/21'
        },
        {
          id: '3',
          date: '09/21/21'
        },
        {
          id: '4',
          date: '08/21/21'
        },
        {
          id: '5',
          date: '07/21/21'
        }
      ],
      taxDocuments: [
        {
          id: '1',
          date: '07/21/21'
        },
        {
          id: '1',
          date: '07/21/22'
        }
      ],
      accountAgreements: [
        {
          id: '1',
          date: '07/21/21',
          title:
            'Limited Electronic Disclosure and Communications Consent Agreement'
        },
        {
          id: '2',
          date: '07/21/21',
          title: 'Deposit Agreement and Disclosures'
        }
      ],
      accountNotices: [
        {
          id: '1',
          date: '07/21/21',
          title: 'Account Notice'
        },
        {
          id: '2',
          date: '07/21/21',
          title: 'Account Notice'
        },
        {
          id: '3',
          date: '07/21/21',
          title: 'Account Notice'
        }
      ],
      maturitySetting: {
        option: 'Renew',
        termLength: '24',
        changeProduct: 'Bump Up Jumbo CD 24 Month',
        scheduledTransferId: ""
      },
      interestAccount: {
        accountNumber: '1234567892000'
      },
      beneficiaries: [
        {
          id: "1",
          name: "Marty Byrde",
          firstName: "Marty",
          lastName: "Byrde",
          percentage: 40,
          tin: '123456789',
          dateOfBirth: '09/27/1972',
          address1: '123 Ozarks Drive',
          city: 'Ozarks',
          state: 'MO',
          zipCode: '65721',
          relationship: 'Spouse',
          type: 'Person',
          isDeleted: false
        },
        {
          id: "2",
          name: "Wendy Byrde",
          firstName: "Wendy",
          lastName: "Byrde",
          percentage: 20,
          tin: '987654321',
          dateOfBirth: '03/15/1977',
          address1: '123 Ozarks Drive',
          city: 'Ozarks',
          state: 'MO',
          zipCode: '65721',
          relationship: 'Spouse',
          type: 'Person',
          isDeleted: false
        },
        {
          id: "3",
          name: "Charlotte Byrde",
          firstName: "Charlotte",
          lastName: "Byrde",
          percentage: 20,
          tin: '123459876',
          dateOfBirth: '08/01/1992',
          address1: '123 Ozarks Drive',
          city: 'Ozarks',
          state: 'MO',
          zipCode: '65721',
          relationship: 'Child',
          type: 'Person',
          isDeleted: false
        },
        {
          id: "4",
          name: "The Northern Trust Compnay",
          trustName: "The Northern Trust Company",
          trustEstablishedDate: "08/12/1889",
          percentage: 10,
          tin: '36-3046063',
          dateOfBirth: '02/05/1997',
          address1: '2500 Sand Hill Rd ',
          address2: 'Suite 150',
          city: 'Menlo Park',
          state: 'CA',
          zipCode: '94025',
          phoneNumber: '+1 (650) 926-9200',
          type: 'Trust',
          isDeleted: false
        },
        {
          id: "5",
          name: "Save the Children Federation",
          charityName: "Save the Children Federation",
          percentage: 10,
          tin: '06-0726487',
          address1: '501 Kings Highway East',
          address2: 'Suite 400',
          city: 'Fairfield',
          state: 'CT',
          zipCode: '06825',
          phoneNumber: '+1 (800) 728-3843',
          email: 'supportercare@savechildren.org',
          type: 'Charity',
          isDeleted: false
        }
      ],
      statements: [
        {
          id: '1',
          date: '07/21/2021'
        },
        {
          id: '2',
          date: '08/21/2021'
        },
        {
          id: '3',
          date: '09/21/2020'
        },
        {
          id: '4',
          date: '09/21/2019'
        },
        {
          id: '5',
          date: '09/21/2018'
        }
      ],
      isBeneficiariesLoaded: false,
      creationDate: '2022-04-21T16:39:25.870+00:00',
      bumpActivated: false,
      isBannerAvailable: false,
      earlyWithdrawal: {
        transferAccount: "",
        transferAmount: 0,
        transferDate: "",
        transferAccountTitle: "",
        optionalAnswer: ""
      },
      earlyWithdrawalSelected: false,
      accountClosed: true,
      accountClosedDate: "11/08/2022",
      isGracePeriod: false,
      gracePeriodFundTransfer: { transferAccount: "", transferAmount: 0, transferType: "", transferAccountId: "", transferId: "" },
      transactions: [],
      isTransactionsLoaded: false,
      cdGroup: "",
      graceTermRenewal: {cdGroup: "", cdTerm: "", apy: "", productCode: ""},
      currentBalance: 180120,
      graceEndDate: "",
      graceStartDate: "",
      earlyWithdrawalPenalty: "",
      loyaltyRate: false,
      pendingGraceClosure: false
    },
    {
      id: '123999',
      accountNumber: '1234567893000',
      accountType: 'Bump',
      accountTitle: "Sports Ticket Fund",
      description: 'Bump Up Jumbo CD 24 Month',
      balance: 506500,
      interestRate: 2.75,
      term: '24 Months',
      maturityDate: '10/08/2023',
      routingNumber: '122402133',
      owner: [
        { name: 'Barry Sanders', role: 'Owner' },
        { name: 'Steve Austin', role: 'Co-owner' }
      ],
      interestPaid: 3251.25,
      interestPaidYTD: 2051.25,
      interestPaidPriorYear: 1200,
      selectedInterestAccount: {
        id: "1",
        accountNumber: '1234567892000',
        accountType: 'Internal',
        accountTitle: "Sports Ticket Fund"
      },
      beneficiaries: [],
      interestAccount: {
        accountNumber: '1234567893000'
      },
      statements: [
        {
          id: '1',
          date: '11/21/22'
        },
        {
          id: '2',
          date: '10/21/22'
        },
        {
          id: '3',
          date: '09/21/22'
        },
        {
          id: '4',
          date: '08/21/22'
        },
        {
          id: '5',
          date: '07/21/22'
        },
        {
          id: '5',
          date: '06/21/22'
        },
        {
          id: '5',
          date: '05/21/22'
        }
      ],
      maturitySetting: {
        option: 'Renew',
        termLength: '24',
        changeProduct: 'Bump Up Jumbo CD 24 Month',
        scheduledTransferId: ""
      },
      accountStatements: [
        {
          id: '1',
          date: '11/21/22'
        },
        {
          id: '2',
          date: '10/21/22'
        },
        {
          id: '3',
          date: '09/21/22'
        },
        {
          id: '4',
          date: '08/21/22'
        },
        {
          id: '5',
          date: '07/21/22'
        },
        {
          id: '5',
          date: '06/21/22'
        },
        {
          id: '5',
          date: '05/21/22'
        }
      ],
      taxDocuments: [
        {
          id: '1',
          date: '07/21/22'
        },
        {
          id: '1',
          date: '07/21/21'
        }
      ],
      accountAgreements: [
        {
          id: '1',
          date: '10/08/21',
          title:
            'Limited Electronic Disclosure and Communications Consent Agreement'
        },
        {
          id: '2',
          date: '10/08/21',
          title: 'Deposit Agreement and Disclosures'
        }
      ],
      accountNotices: [
        {
          id: '1',
          date: '07/21/22',
          title: 'Account Notice'
        },
        {
          id: '2',
          date: '01/21/22',
          title: 'Account Notice'
        },
        {
          id: '3',
          date: '10/08/21',
          title: 'Account Notice'
        }
      ],
      isBeneficiariesLoaded: false,
      creationDate: '2021-10-08T16:39:25.870+00:00',
      isBannerAvailable: false,
      earlyWithdrawal: {
        transferAccount: "",
        transferAmount: 0,
        transferDate: "",
        transferAccountTitle: "",
        optionalAnswer: ""
      },
      earlyWithdrawalSelected: false,
      accountClosed: false,
      accountClosedDate: "",
      isGracePeriod: false,
      gracePeriodFundTransfer: { transferAccount: "", transferAmount: 0, transferType: "", transferAccountId: "", transferId: "" },
      transactions: [],
      isTransactionsLoaded: false,
      cdGroup: "",
      graceTermRenewal: {cdGroup: "", cdTerm: "", apy: "", productCode: ""},
      currentBalance: 210120,
      graceEndDate: "",
      graceStartDate: "",
      earlyWithdrawalPenalty: "",
      loyaltyRate: false,
      pendingGraceClosure: false
    },
    {
      id: '123456',
      accountNumber: '1234567896000',
      accountType: 'CD',
      accountTitle: "Gasoline Fund",
      description: 'Jumbo CD 12 Month',
      balance: 306500,
      interestRate: 3.75,
      term: '12 Months',
      maturityDate: '10/08/2023',
      routingNumber: '122402133',
      owner: [
        { name: 'Barry Sanders', role: 'Owner' },
        { name: 'Steve Austin', role: 'Co-owner' }
      ],
      interestPaid: 3251.25,
      interestPaidYTD: 2051.25,
      interestPaidPriorYear: 1200,
      selectedInterestAccount: {
        id: "1",
        accountNumber: '1234567892000',
        accountType: 'Internal',
        accountTitle: "Sports Ticket Fund"
      },
      beneficiaries: [],
      interestAccount: {
        accountNumber: '1234567893000'
      },
      statements: [
        {
          id: '1',
          date: '11/21/22'
        },
        {
          id: '2',
          date: '10/21/22'
        },
        {
          id: '3',
          date: '09/21/22'
        },
        {
          id: '4',
          date: '08/21/22'
        },
        {
          id: '5',
          date: '07/21/22'
        },
        {
          id: '5',
          date: '06/21/22'
        },
        {
          id: '5',
          date: '05/21/22'
        }
      ],
      maturitySetting: {
        option: 'Renew',
        termLength: '12',
        changeProduct: 'Jumbo CD 12 Month',
        scheduledTransferId: ""
      },
      accountStatements: [
        {
          id: '1',
          date: '11/21/22'
        },
        {
          id: '2',
          date: '10/21/22'
        },
        {
          id: '3',
          date: '09/21/22'
        },
        {
          id: '4',
          date: '08/21/22'
        },
        {
          id: '5',
          date: '07/21/22'
        },
        {
          id: '5',
          date: '06/21/22'
        },
        {
          id: '5',
          date: '05/21/22'
        }
      ],
      taxDocuments: [
        {
          id: '1',
          date: '07/21/22'
        },
        {
          id: '1',
          date: '07/21/21'
        }
      ],
      accountAgreements: [
        {
          id: '1',
          date: '10/08/21',
          title:
            'Limited Electronic Disclosure and Communications Consent Agreement'
        },
        {
          id: '2',
          date: '10/08/21',
          title: 'Deposit Agreement and Disclosures'
        }
      ],
      accountNotices: [
        {
          id: '1',
          date: '07/21/22',
          title: 'Account Notice'
        },
        {
          id: '2',
          date: '01/21/22',
          title: 'Account Notice'
        },
        {
          id: '3',
          date: '10/08/21',
          title: 'Account Notice'
        }
      ],
      isBeneficiariesLoaded: false,
      creationDate: '2022-10-08T16:39:25.870+00:00',
      isBannerAvailable: false,
      earlyWithdrawal: {
        transferAccount: "",
        transferAmount: 0,
        transferDate: "",
        transferAccountTitle: "",
        optionalAnswer: ""
      },
      earlyWithdrawalSelected: false,
      accountClosed: false,
      accountClosedDate: "",
      isGracePeriod: true,
      gracePeriodFundTransfer: { transferAccount: "", transferAmount: 0, transferType: "", transferAccountId: "", transferId: "" },
      transactions: [],
      isTransactionsLoaded: false,
      cdGroup: "",
      graceTermRenewal: {cdGroup: "", cdTerm: "", apy: "", productCode: ""},
      currentBalance: 140120,
      graceEndDate: "",
      graceStartDate: "",
      earlyWithdrawalPenalty: "",
      loyaltyRate: false,
      pendingGraceClosure: false
    }
  ]
};