import { IControl } from "../Form/Control/IControl.interface"
import { IFormContext, TControlDispatch } from "../useForm/useForm"

/**A use hook to handle the logic for the text input */
export const useTextInput = (form: IFormContext, control: IControl, dispatchControlChanges: TControlDispatch) => {

    /**handles the onChange of the text input component */
    const onChangeHandler = (e: any) => {
        form.setValue(control.name, e.target.value)
        /**If there are error than run validations on the control */
        if(control.errors.length){
            form.runValidations(control.name)
        }
        dispatchControlChanges()
    }

    /**Handles the onBlur event for the text input comonent */
    const handleOnBlur = () => {
        form.runValidations(control.name)
        dispatchControlChanges()
    }

    return { onChangeHandler, handleOnBlur }
}