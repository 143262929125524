import './YodleePrimingScreen.scss'
import yodleeLogo from '../../../../assets/yodleeLogo.svg'
import blueCheckmark from '../../../../assets/blueCheckmark.svg'
import arrowLeftBlue from '../../../../assets/arrowLeftBlue.svg'
import { useLinkExternalAccount } from '../../Context/useLinkExternalAccount'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import { useHistory } from 'react-router'
import { getYodleeToken_API } from '../../../../api/User/yodleeServices'
import { useToastMessage } from '../../../../Context/ToastContext/useToastContext'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'
import { IEDPErrorInterface, edpErrorReporting_API } from '../../../../api/edpErrorReporting'

const YodleePrimingScreen = () => {
    const { windowState } = useWindowState()
    const { step, setStep, postYodleeFlow, setYodleeToken, setProviderAccountId } = useLinkExternalAccount()
    const history = useHistory()
    const { setToastMessage, clearToast, message, type } = useToastMessage()

    /**handles the continue button click event */
    const handleContinue = async () => {
        try{
            const response = await getYodleeToken_API()
            setYodleeToken(response.data.token.accessToken)
            // setYodleeToken("sdoKQttTjs8IVaGA")
            if(response.data.token.accessToken === "1"){
                setProviderAccountId("1")
            }
            clearToast()
            setStep(6)
            return Promise.resolve(true)
        }
        catch(error: any){
            console.log("ERROR RETREIVING THE TOKEN")
            let errorReturn: any = error.response ? JSON.parse(JSON.stringify(error.response)) : ""
            setToastMessage("ERROR", "Unable to load Yodlee. Select continue to try again.")
            let edpErrorObject: IEDPErrorInterface = {
                category: "External_Account",
                errorType: "API_error",
                errorMessage: [{message: errorReturn?.data?.errorMsg[0] || ""}, {message: "INSTANT VERIFICATION (GET YODLEE TOKEN)"}],
                timeStamp: new Date().toISOString(),
                additionalDetails: {
                    externalAccounts: [{
                        errorCode: errorReturn.status || "",
                        verificationMethod: "YODLEE"
                    }]        
                }
            }
            try{
                edpErrorReporting_API(edpErrorObject)
            }
            catch{}
            return Promise.resolve(false)
        }
    }

    return (
        <div className="yodlee-priming-screen-wrap">
            <div className={`header-wrap ${windowState}`}>
                <span className={windowState !== "Mobile" ? "header regular" : "header small"}>Link external account</span>
                <span className={windowState !== "Mobile" ? "sub-header h3" : "sub-header h4"}>Instant verification</span>
            </div>
            <div className="data-wrap">
                <div className="content-wrap">
                    <img className="image" src={yodleeLogo} alt="yodlee logo" />
                    <span className="title-text h4">Credit One Bank uses Yodlee to verify your bank account</span>
                    <div className="text-wrap margin">
                        <img className="checkmark" src={blueCheckmark} alt="blue checkmark" />
                        <div className="right-side">
                            <span className="text-tile boldRegular">It's Secure</span>
                            <span className="text-subtitle regular">Yodlee links securely to your bank account using your online bank credentials.</span>
                        </div>
                    </div>
                    <div className="text-wrap">
                        <img className="checkmark" src={blueCheckmark} alt="blue checkmark" />
                        <div className="right-side">
                            <span className="text-tile boldRegular">It's Private</span>
                            <span className="text-subtitle regular">Your information remains private, and the process takes just a few minutes. </span>
                        </div>
                    </div>
                    <span className="consent-text small">By selecting 'Continue,' you agree to allow Yodlee to validate your bank information.</span>
                </div>
                <div className="button-wrapper">
                    {windowState !== "Mobile" ?
                        <>
                            <div className="left-side" onClick={() => {clearToast(); setStep(2)}}>
                                <img src={arrowLeftBlue} alt="blue left arrow" />
                                <span className="blue-text regular">Back</span>
                            </div>
                            <div className="right-side">
                                <button className="Button btn-secondary" onClick={() => {clearToast(); history.push("/user/profile/external-accounts")}} >Cancel</button>
                                <Button 
                                    title="Continue"
                                    className="Button btn-primary"
                                    onClick={() => handleContinue()}
                                    id={TIMER_IDS.YODLEE_LAUNCH_FASTLINK}
                                />
                            </div>
                        </>
                        :
                        <Button 
                            title="Continue"
                            className="Button btn-primary mobile"
                            onClick={() => handleContinue()}
                            id={TIMER_IDS.YODLEE_LAUNCH_FASTLINK}
                        />
                    }
                </div>
            </div>
            {message !== "" && <ToastMessageDB type={type} message={message} disableTimer={true}/>}
        </div>
    )
}

export default YodleePrimingScreen