import { useHistory } from "react-router"
import { useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { MobileLayout } from "../../layouts/MobileLayout/MobileLayout"
import { selectedAccount } from "../../slices/selectedAccountSlice/selectedAccountSlice"
import { useGracePeriod } from "../../useHooks/pageHooks/useGracePeriod"
import DesktopGracePeriod from "./DesktopGracePeriod/DesktopGracePeriod"
import MobileGracePeriod from "./MobileGracePeriod/MobileGracePeriod"

/**The wrapper page for Grace Period, this page will determine which layout state to use */
const GracePeriodWrapper = () => {
    const { windowState, changeCD, setChangeCD, fundTransfer, setFundTransfer, closeAccount, setCloseAccount, chosenTerm, setChosenTerm,
            showTermChange, setShowTermChange, transferType, setTransferType, firstStepCompleted, setFirstStepCompleted,
            openCancelFunds, setOpenCancelFunds, transferAccount, setTransferAccount, showPhoneNumber, setShowPhoneNumber } = useGracePeriod()
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const history = useHistory()

    if(selectedAccount.id !== "" && (selectedAccount.accountClosed || selectedAccount.pendingGraceClosure)){
        history.push(`/accounts/${selectedAccount.id}`)
    }

    return (
        <>
        {windowState !== "Mobile" ?
            <>
            {windowState === "Desktop" ?
                <DesktopLayout>
                    <DesktopGracePeriod 
                        changeCD={changeCD}
                        setChangeCD={setChangeCD}
                        fundTransfer={fundTransfer}
                        setFundTransfer={setFundTransfer}
                        closeAccount={closeAccount}
                        setCloseAccount={setCloseAccount}
                        chosenTerm={chosenTerm}
                        setChosenTerm={setChosenTerm}
                        showTermChange={showTermChange}
                        setShowTermChange={setShowTermChange}
                        transferType={transferType}
                        setTransferType={setTransferType}
                        openCancelFunds={openCancelFunds}
                        setOpenCancelFunds={setOpenCancelFunds}
                        transferAccount={transferAccount}
                        setTransferAccount={setTransferAccount}
                        showPhoneNumber={showPhoneNumber}
                        setShowPhoneNumber={setShowPhoneNumber}
                    />
                </DesktopLayout>
                :
                <HybridLayout>
                    <DesktopGracePeriod 
                        changeCD={changeCD}
                        setChangeCD={setChangeCD}
                        fundTransfer={fundTransfer}
                        setFundTransfer={setFundTransfer}
                        closeAccount={closeAccount}
                        setCloseAccount={setCloseAccount} 
                        chosenTerm={chosenTerm}
                        setChosenTerm={setChosenTerm}
                        showTermChange={showTermChange}
                        setShowTermChange={setShowTermChange}
                        transferType={transferType}                   
                        setTransferType={setTransferType}
                        openCancelFunds={openCancelFunds}
                        setOpenCancelFunds={setOpenCancelFunds}
                        transferAccount={transferAccount}
                        setTransferAccount={setTransferAccount}
                        showPhoneNumber={showPhoneNumber}
                        setShowPhoneNumber={setShowPhoneNumber}
                    />
                </HybridLayout>
            }
            </>
            :
            <MobileLayout>
                <MobileGracePeriod 
                    changeCD={changeCD}
                    setChangeCD={setChangeCD}
                    fundTransfer={fundTransfer}
                    setFundTransfer={setFundTransfer}
                    closeAccount={closeAccount}
                    setCloseAccount={setCloseAccount} 
                    chosenTerm={chosenTerm}
                    setChosenTerm={setChosenTerm}
                    showTermChange={showTermChange}
                    setShowTermChange={setShowTermChange}
                    transferType={transferType}
                    setTransferType={setTransferType}
                    firstStepCompleted={firstStepCompleted} 
                    setFirstStepCompleted={setFirstStepCompleted} 
                    openCancelFunds={openCancelFunds}
                    setOpenCancelFunds={setOpenCancelFunds}  
                    transferAccount={transferAccount}
                    setTransferAccount={setTransferAccount}
                    showPhoneNumber={showPhoneNumber}
                    setShowPhoneNumber={setShowPhoneNumber}
                />
            </MobileLayout>
        }
        </>
    )
}

export default GracePeriodWrapper