// import { useLoquateOnForm, AddressInputWrapper } from '../../../../../../shared/LoquateAutoComplete/LoquateAutoComplete'
import { useEditBeneficiaryCharity } from '../../../../../../useHooks/componentHooks/useEditCharity/useEditBeneficiaryCharity'
import { Button, TIMER_IDS } from '../../../../../Button/Button'
import RXInput from '../../../../../RXInput/RXInput'
import './DesktopEditCharity.scss'
import { IDesktopEditCharityProps } from './interfaces/IDesktopEditCharityProps'
import { useBriteVerifyOnForm} from '../../../../../../shared/BriteVerify/BriteVerify'

/**
 * The component renders the Edit Charity component for the desktop version.
 */
const DesktopEditCharity = (props: IDesktopEditCharityProps) => {
    const { editPerson, setEditPerson, setEditProfile, setEditBeneficiary, selectedBeneficiary, setDeleteBeneficiary, form, dispatchForm } = props
    const { handleOnSubmit, formFilled, } = useEditBeneficiaryCharity(setEditProfile, setEditBeneficiary, selectedBeneficiary, form)

    // const { onLoquateSelection, isAddressValid } = useLoquateOnForm(form, dispatchForm)
    const { isEmailValid } = useBriteVerifyOnForm(form, dispatchForm)

    const handleValidation = async () => {

        try {
            // await isAddressValid();
            await isEmailValid();
            await handleOnSubmit();
            return Promise.resolve(true)
        }
        catch (err) {

        }

    }

    return (
        <div className="Desktop-Beneficiary-Edit-Charity">
            <div className="edit-charity-page-wrap">
                <div className="Edit-Charity-Details">
                    <BeneficiaryEditButtons
                        editPerson={editPerson}
                        setDeleteBeneficiary={setDeleteBeneficiary}
                        setEditProfile={setEditProfile}
                        form={form}
                        dispatchForm={dispatchForm}
                        setEditPerson={setEditPerson}
                        formFilled={formFilled}
                        handleValidation={handleValidation}
                    />
                    <div className="form-wrapper">
                        <span className="Charity-Info-Header boldLarge">Charity or Non-Profit Information</span>
                        <div className="inputs-wrapper">
                            <RXInput control={form.controls.charityName} />
                            <RXInput control={form.controls.tin} />
                        </div>
                        <div className="inputs-wrapper">
                            <RXInput control={form.controls.address1} />
                            <RXInput control={form.controls.address2} />
                        </div>
                        <div className="inputs-wrapper">
                            <RXInput control={form.controls.city} />
                            <RXInput control={form.controls.state} />
                            <RXInput control={form.controls.zipCode} />
                        </div>

                        <span className="Additional-Info-Header boldLarge">Additional Information</span>
                        <div className="inputs-wrapper">
                            <RXInput control={form.controls.phoneNumber} />
                            <RXInput control={form.controls.email} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DesktopEditCharity


export const BeneficiaryEditButtons = (props: any) => {
    const { editPerson, setDeleteBeneficiary, setEditProfile, form, dispatchForm, setEditPerson, formFilled, handleValidation } = props;
    return <div className="Chart-Header">
        <div className="Chart-Settings">
            <span className="Header-Text h4">Beneficiary Settings</span>
            <span className="Regular-Text regular">Make sure your beneficiary’s information is up to date. Choose edit to make changes. </span>
        </div>
        <div className="button-wrap">
            {!editPerson ?
                <Button className="Button btn-secondary margin boldButtonLarge" onClick={() => setDeleteBeneficiary(true)} title="Remove" />
                :
                <Button className="Button btn-secondary margin boldButtonLarge" onClick={() => { setEditPerson(false); setEditProfile(false); form.resetForm(); dispatchForm() }} title="Cancel" />
            }
            {!editPerson ?
                <Button className="Button btn-primary boldButtonLarge" onClick={() => { setEditPerson(true); form.enableAllInputs(); dispatchForm() }} title="Edit" />
                :
                <Button 
                    className={formFilled ? "Button btn-primary boldButtonLarge" : "Button btn-primary inactive boldButtonLarge"} 
                    onClick={handleValidation} 
                    title="Save" 
                    isDisabled={!formFilled}
                    id={TIMER_IDS.BENEFICIARY_EDIT}    
                />
            }
        </div>
    </div>
}