import { useEffect, useState, useRef } from "react"
import { emptyTransfer, getScheduledTransferHistory, getTransferHistory, ITransferHistory } from "../../api/transfers"
import { useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { useWindowState } from "../../Context/AccountContext/useWindowState"
import { getTodaysDate } from "../../utils/getCurrentDate"
import { populateTransfers } from "../../utils/populateTransfers"
import { useHistory } from "react-router-dom"
import { ITransfersFromDetailsState } from "../../Pages/Transfers/interfaces/ITransfersFromDetailsState"

/**A custom use hook for the transfers wrapper page */
export const useTransfers = () => {
    const history = useHistory();
    const { windowState } = useWindowState()
    const [selectedFromAccount, setSelectedFromAccount] = useState<{ title: string, type: "Savings" | "", lastFour: string, accountId: string, balance: number }>({
        title: "",
        type: "",
        lastFour: "",
        accountId: "",
        balance: 0
    })
    const [selectedToAccount, setSelectedToAccount] = useState<{ title: string, type: "Savings" | "", lastFour: string, accountId: string, balance: number }>({
        title: "",
        type: "",
        lastFour: "",
        accountId: "",
        balance: 0
    })
    const [selectionOptions, setSelectionOptions] = useState<Array<{ title: string, type: "Savings" | "", lastFour: string, accountID: string, balance: number }>>([])
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const linkedExternalAccounts = useAppSelector((state: RootState) => state.userInformation.externalAccounts)
    const [transferAmount, setTransferAmount] = useState<number>()
    const [transferDate, setTransferDate] = useState<string>("")
    const [topCardIndex, setTopCardIndex] = useState<number>(0)
    // const [transferHistory, setTransferHistory] = useState<Array<ITransferHistory>>(mockTransfers)
    const [transferHistory, setTransferHistory] = useState<Array<ITransferHistory>>([])
    const [selectedTransfer, setSelectedTransfer] = useState<ITransferHistory>(emptyTransfer)
    const [scheduledTransfers, setScheduledTransfers] = useState<Array<ITransferHistory>>([])
    const [totalTransfers, setTotalTransfers] = useState<number>(0)
    const selectedTransferRef = useRef<string>("");
    const [gotTransferHistory, setGotTransferHistory] = useState<"Loading" | "Success" | "Failed">("Loading")
    const [gotScheduledTransfers, setGotScheduledTransfers] = useState<"Loading" | "Success" | "Failed">("Loading")
    const gotExternalAccounts = useAppSelector((state: RootState) => state.userInformation.gotExternalAccounts)
    const [transferErrored, setTransferErrored] = useState<boolean>(false)


    /**calls function to get all transfers on render */
    useEffect(() => {
        getTransfers()
        getScheduledTransfers()
    }, [])

    /**
     * This sets the flow to the confirm page and prefills all the values if history.location.state is present with the values.
     * Used when user makes a transfers from the savings account details page
     */
    useEffect(() => {
        if (history.location.state !== undefined && history.location.state !== null) {
            const STATE: ITransfersFromDetailsState = history.location.state as ITransfersFromDetailsState;
            setTopCardIndex(STATE.topCardIndex)
            setSelectedToAccount(STATE.selectedToAccount)
            setSelectedFromAccount(STATE.selectedFromAccount)
            setTransferAmount(STATE.transferAmount)
            setTransferDate(STATE.transferDate)
        }
        //eslint disabling on the next line because we only want to run this useEffect whenever history.location.state is updated. Which should only be once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.state])

    /**calls to get the transfer history on render */
    const getTransfers = async () => {
        try {
            const response = await getTransferHistory(0, 10, "date", "DESC")
         
            const response2 = await getTransferHistory(0, response.data.total, "date", "DESC")
         
            setTotalTransfers(response2.data.transferOrderHistory.length)
            setTransferHistory(populateTransfers(response2.data.transferOrderHistory, ""))
            setGotTransferHistory("Success")
        }
        catch {
            console.log("ERROR GETTING TRANSFER HISTORY")
            setGotTransferHistory("Failed")
        }
    }

    /**calls to get scheduled transfers history on render */
    const getScheduledTransfers = async () => {
        try {
            const response = await getScheduledTransferHistory("date", "DESC")
            setScheduledTransfers(populateTransfers(response.data.transferOrderHistory, "Scheduled"))
            setGotScheduledTransfers("Success")
        }
        catch {
            console.log("ERROR GETTING SCHEDULED TRANSFER HISTORY")
            setGotScheduledTransfers("Failed")
        }
    }

    /**gets the current date and defaults it as the transfer date */
    const getCurrentDate = () => {
        setTransferDate(getTodaysDate());
    }

    const IS_TRANSFER_DATE_EMPTY = transferDate === '';
    /**Default the transfer date to todays date on render */
    useEffect(() => {
        /** If transferDate is empty and history.location.state doesn't exist */
        if (IS_TRANSFER_DATE_EMPTY && !history.location.state) {
            getCurrentDate()
        }
        /** If transferDate is not empty and history.location.state exists. This prevents us from rewriting over the transferDate we get from state. */
        if (!IS_TRANSFER_DATE_EMPTY && history.location.state) {
            history.replace({})
        }
        // Disabling eslint for this line since we only want to render this when IS_TRANSFER_DATE_EMPTY updates and nothing else
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IS_TRANSFER_DATE_EMPTY]);

    /**Once accountInformation is rendered, build the accounts for the selection drop downs */
    useEffect(() => {
        if (accountInformation.length && accountInformation[0].id !== "") {
            buildSelectionOptions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInformation, gotExternalAccounts])

    /**Builds the accounts for the select dropdowns */
    const buildSelectionOptions = () => {
        let tempAccounts: Array<{ title: string, type: "Savings" | "", lastFour: string, accountID: string, balance: number }> = []
        let addAccount = { title: "CREDIT ONE ACCOUNTS", type: "" as "", lastFour: "", accountID: "", balance: 0 }
        tempAccounts.push(addAccount)
        for (let i = 0; i < accountInformation.length; i = i + 1) {
            if (accountInformation[i].accountType === "Savings" && !accountInformation[i].accountClosed) {
                let addAccount = {
                    title: accountInformation[i].accountTitle,
                    type: "Savings" as "Savings",
                    lastFour: accountInformation[i].accountNumber,
                    accountID: accountInformation[i].id,
                    balance: accountInformation[i].balance
                }
                tempAccounts.push(addAccount)
            }
        }
        addAccount = { title: "EXTERNAL ACCOUNTS", type: "" as "", lastFour: "", accountID: "", balance: 0 }
        tempAccounts.push(addAccount)
        for (let i = 0; i < linkedExternalAccounts.length; i = i + 1) {
            if (linkedExternalAccounts[i].status === "Active" || linkedExternalAccounts[i].status === "Linked") {
                let addAccount = {
                    title: linkedExternalAccounts[i].accountNickName,
                    type: "" as "",
                    lastFour: linkedExternalAccounts[i].lastFour as string,
                    accountID: linkedExternalAccounts[i].id,
                    balance: 0
                }
                tempAccounts.push(addAccount)
            }
        }
        if (tempAccounts.length) {
            setSelectionOptions(tempAccounts)
        }
    }

    useEffect(() => {
        if (topCardIndex === 2) {
            setTopCardIndex(0)
            setSelectedFromAccount({
                title: "",
                type: "",
                lastFour: "",
                accountId: "",
                balance: 0
            })
            setSelectedToAccount({
                title: "",
                type: "",
                lastFour: "",
                accountId: "",
                balance: 0
            })
            setTransferAmount(undefined)
            getCurrentDate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduledTransfers])

    /**Handles the close button onClick event found in the cancel transfer error modal */
    const handleClose = async (setNewTransfers: React.Dispatch<React.SetStateAction<Array<ITransferHistory>>>) => {
        try {
            // const firstCall = await getTransferHistory(0, 10, "date", "DESC")
            // console.log("first call is :", firstCall)
            // let response = await getTransferHistory(0, firstCall.data.total, "date", "DESC")
            // console.log("THE RESPONSE IS :", response)
            // let tempArray = populateTransfers(response.data.transferOrderHistory, "")
            let tempArray = transferHistory
            selectedTransferRef.current = selectedTransfer.id
            let newTransfer: ITransferHistory = {
                ...selectedTransfer, status: ""
            }
            tempArray.unshift(newTransfer)
            setTransferHistory(JSON.parse(JSON.stringify(tempArray)))
            setTransferErrored(false)
        }
        catch {
            console.log("FAILED TO GET THE TRANSFERS")
        }
    }


    return {
        windowState, selectedFromAccount, setSelectedFromAccount, selectedToAccount, setSelectedToAccount, selectionOptions,
        transferAmount, setTransferAmount, transferDate, setTransferDate, topCardIndex, setTopCardIndex, transferHistory, setTransferHistory,
        selectedTransfer, setSelectedTransfer, scheduledTransfers, setScheduledTransfers, totalTransfers, handleClose, selectedTransferRef,
        gotTransferHistory, gotScheduledTransfers, transferErrored, setTransferErrored
    }
}