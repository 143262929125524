import axios from "axios";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { bffBaseUrl, token } from "../utils/globalVariables";
import { savingsTransactions } from './getTransactionHistory/mocks/getTransactionSavingsHistory';
import { ITransactionHistoryData } from './getTransactionHistory/types/ITransactionHistory.type';
import { isUTCFormat } from "../utils/DateUtils";

export type { IGetTransactionHistoryResponse, ITransactionHistoryData, ITransactionHistoryDataMock } from './getTransactionHistory/types/ITransactionHistory.type';

export { cdTransactions } from './getTransactionHistory/mocks/getTransactionCDHistory';
export { savingsTransactions } from './getTransactionHistory/mocks/getTransactionSavingsHistory';

const cleanDateFormat = (date: string) => {
  if(date){
    const [year, month, day] = date.split('-')
    return `${month}/${day}/${year}`
  }
  return ""
}

/**
 * Constructs the transaction history data type to be used thruought the application
 */
export const buildTransactionHistoryData = (transactionData: any, accountId: string) => {

  let transactionHistoryObjectArray: Array<ITransactionHistoryData> = []
  let tempHistoryObjectArray: ITransactionHistoryData
  // let mockTransactions = JSON.parse(JSON.stringify(cdTransactions.data))
  if (transactionData) {
    //first use the actual api data
    for (let i = 0; i < transactionData.length; i = i + 1) {
      tempHistoryObjectArray = {
        id: transactionData[i].id,
        memo: transactionData[i].memo || "",
        description: transactionData[i].description || "",
        amount: transactionData[i].transactionType === "Debit" ? - transactionData[i].amount : transactionData[i].amount,
        effectiveDate: !isUTCFormat(transactionData[i].postDate) ? transactionData[i].postDate : transactionData[i].postDate,
        payerName: 'Jim Harbaugh',
        type: transactionData[i].transactionType,
        institution: transactionData[i].institution,
        availableNow: transactionData[i].amount,
        accountType: "CD",
        accountNumber: transactionData[i].accountId,
        holdAmount: 0,
        holdAmountReason: "",
        currentBalance: transactionData[i].currentBalance,
        status: "Confirmed",
        availableOn: cleanDateFormat(transactionData[i].postDate),
        index: transactionData[i].index,
        isAmountHold: transactionData[i].isAmountHold
      }
      transactionHistoryObjectArray.push(tempHistoryObjectArray)
    }
    
    return transactionHistoryObjectArray
  }
  else {
    return transactionData
  }
}

/**
 * Constructs the transaction history data type to be used thruought the application, this is mock data
 */
export const buildSavingsTransactionHistoryData = (transactionData: any) => {
  let transactionHistoryObject: Array<ITransactionHistoryData> = []
  let mockTransactions = JSON.parse(JSON.stringify(savingsTransactions.data))

  if (transactionData) {
    for (let i = 0; i < mockTransactions.length; i = i + 1) {
      transactionHistoryObject[i] = {
        id: mockTransactions[i].id,
        memo: mockTransactions[i].memo,
        description: mockTransactions[i].description,
        amount: mockTransactions[i].amount,
        effectiveDate: mockTransactions[i].effectiveDate,
        payerName: mockTransactions[i].payerName,
        type: mockTransactions[i].type,
        institution: mockTransactions[i].institution,
        availableNow: mockTransactions[i].availableNow,
        accountType: mockTransactions[i].accountType,
        accountNumber: mockTransactions[i].accountNumber,
        holdAmount: mockTransactions[i].holdAmount,
        holdAmountReason: mockTransactions[i].holdAmountReason,
        currentBalance: mockTransactions[i].currentBalance,
        status: mockTransactions[i].status,
        availableOn: mockTransactions[i].availableOn,
        index: mockTransactions[i].index
      }
    }

    return transactionHistoryObject
  }
  else {
    return transactionData
  }
}



/**
 * get API call for Transaction History
 * @param accountId string
 * @returns transaction history
 */
export const getTransactionHistory_API = async (customerId: string, accountId: string, offSet: number, size: number) => {
  return axios.get(`${bffBaseUrl}/accounts/${accountId}/transactions?offSet=${offSet}&size=${size}`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
};
