// import { AddressInputWrapper, useLoquateOnForm } from '../../../../../../shared/LoquateAutoComplete/LoquateAutoComplete';
import { useEditBeneficiaryPerson } from '../../../../../../useHooks/componentHooks/useEditBeneficiaryPerson/useEditBeneficiaryPerson';
// import { Button } from '../../../../../Button/Button';
import RXInput from '../../../../../RXInput/RXInput';
import { BeneficiaryEditButtons } from '../DesktopEditCharity/DesktopEditCharity';
import './DesktopEditPerson.scss'
import { IDesktopEditPersonProps } from './interfaces/IDesktopEditPersonProps';
import { useBriteVerifyOnForm } from '../../../../../../shared/BriteVerify/BriteVerify';

/**
 * Renders the desktop version of the edit beneifciary for a 'person'
 */
const DesktopEditPerson = (props: IDesktopEditPersonProps) => {
    const { editPerson, setEditPerson, setEditProfile, setEditBeneficiary, selectedBeneficiary, setDeleteBeneficiary, form, dispatchForm } = props
    const { handleOnSubmit, formFilled } = useEditBeneficiaryPerson(setEditProfile, setEditBeneficiary, selectedBeneficiary, form)

    // const { onLoquateSelection, isAddressValid } = useLoquateOnForm(form, dispatchForm)
    const { isEmailValid } = useBriteVerifyOnForm(form, dispatchForm)

    const handleValidation = async () => {

        try {
            // await isAddressValid();
            await isEmailValid();
            await handleOnSubmit();
            return Promise.resolve(true)
        }
        catch (err) {

        }

    }

    return (
        <div className="Desktop-Beneficiary-Edit-Profile">
            <div className="edit-person-page-wrap">
                <div className="Edit-Person-Details">
                    <BeneficiaryEditButtons
                        editPerson={editPerson}
                        setDeleteBeneficiary={setDeleteBeneficiary}
                        setEditProfile={setEditProfile}
                        form={form}
                        dispatchForm={dispatchForm}
                        setEditPerson={setEditPerson}
                        formFilled={formFilled}
                        handleValidation={handleValidation}
                    />
                    <div className="form-wrapper">
                        <span className="Person-Info-Header boldLarge">Personal Information</span>
                        <div className="Inputs-Wrapper">
                            <RXInput control={form.controls.firstName} className="third" />
                            <RXInput control={form.controls.middleName} className="quarter" />
                            <RXInput control={form.controls.lastName} className="third" />
                        </div>
                        <div className="Inputs-Wrapper">
                            <RXInput control={form.controls.ssn} />
                        </div>
                        <span className="Additional-Info-Header boldLarge">Additional Information</span>
                        <div className="Inputs-Wrapper">
                            <RXInput control={form.controls.dateOfBirth} className="quarter" />
                            <RXInput control={form.controls.relationship} className="half" />
                        </div>
                        <div className="Inputs-Wrapper">
                            <RXInput control={form.controls.address1} className="half" />
                            <RXInput control={form.controls.address2} className="half" />
                        </div>
                        <div className="Inputs-Wrapper">
                            <RXInput control={form.controls.city} className="third" />
                            <RXInput control={form.controls.state} className="third" />
                            <RXInput control={form.controls.zipCode} className="third" />
                        </div>
                        <div className="Inputs-Wrapper">
                            <RXInput control={form.controls.phoneNumber} className="quarter" />
                            <RXInput control={form.controls.email} className="quarter" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesktopEditPerson