import { useAppSelector } from '../../../../../app/hooks'
import { RootState } from '../../../../../app/store'
import { calculateGracePeriodDates } from '../../../../../utils/gracePeriodEnd'
import './DesktopGraceLanding.scss'
import bluePencil from '../../../../../assets/bluePencil.svg'
import maturityCalendar from '../../../../../assets/maturityCalendar.svg'
import greyRightArrow from '../../../../../assets/greyRightArrow.svg'
import { useHistory } from 'react-router-dom'
import { calculateMaturityDate } from '../../../../../utils/calculateMaturityDate'
import { IGraceLandingPageProps } from '../interfaces/IGraceLandingPageProps'
import { convertDateTommddyyyy } from '../../../../../utils/DateUtils'
import { displayCurrency } from '../../../../../utils/displayCurrency'
import redTrashCan from '../../../../../assets/redTrashCan.svg'
import blueDownChevron from '../../../../../assets/blueDownChevron.svg'
import { dateLongHand } from '../../../../../utils/dateLongHand'


/**Renders the desktop version of the Grace Period landing page */
const DesktopGraceLanding = (props: IGraceLandingPageProps) => {
    const history = useHistory()
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const { setChangeCD, setFundTransfer, setCloseAccount, transferType, setOpenCancelFunds, showPhoneNumber, setShowPhoneNumber } = props
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

    return (
        <>
            {isAccountDetailsLoaded !== "Loading" ?
                <>
                    <div className="grace-landing-card-wrap">
                        <div className="header-text-wrap">
                            <span className="header-text h2">Grace period options</span>
                            <span className="sub-header-text regular">Your CD is currently in its 10-day grace period. You can make changes to the term, or add or withdraw funds, until {dateLongHand(convertDateTommddyyyy(new Date(selectedAccount.graceEndDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})))}.</span>
                        </div>
                        <div className="card-details">
                            <span className="title h4">Your current CD details</span>
                            <div className="data-wrap">
                                <div className="left-side">
                                    <span className="subject boldSmall">Renewed on</span>
                                    <span className="value regular">{convertDateTommddyyyy(new Date(selectedAccount.graceStartDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"}))}</span>
                                </div>
                            </div>
                            <div className="bottom-border" />
                            <div className="data-wrap">
                                <div className="left-side">
                                    <span className="subject boldSmall">Term</span>
                                    <div className="bottom-row">
                                        <span className="value regular">{selectedAccount.graceTermRenewal.cdGroup} {selectedAccount.graceTermRenewal.cdTerm}-month - {selectedAccount.graceTermRenewal.apy.substring(0, 4)}%</span>
                                        <div className="pill-wrap">
                                            {selectedAccount.isGracePeriod && <span className="maturity-pill mobileSemiboldSmall">Matures on {convertDateTommddyyyy(calculateMaturityDate(selectedAccount.graceStartDate, selectedAccount.graceTermRenewal.cdTerm))}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="right-side" onClick={() => setChangeCD(true)}>
                                    <img src={bluePencil} alt="edit cd" />
                                    <span className="link-text regular">Change term</span>
                                </div>
                            </div>
                            <div className="bottom-border" />
                            <div className="data-wrap">
                                <div className="left-side">
                                    <span className="subject boldSmall">Add or withdraw funds</span>
                                    <span className="value regular">
                                        {selectedAccount.gracePeriodFundTransfer.transferAccount !== "" ?
                                            transferType === "Add" ?
                                                `Transfer ${displayCurrency(selectedAccount.gracePeriodFundTransfer.transferAmount as number)} from ${selectedAccount.gracePeriodFundTransfer.transferAccount}`
                                                :
                                                `Transfer ${displayCurrency(selectedAccount.gracePeriodFundTransfer.transferAmount as number)} from ${selectedAccount.accountTitle} ${selectedAccount.accountNumber.slice(selectedAccount.accountNumber.length - 4, selectedAccount.accountNumber.length)}`
                                            :
                                            `No transfer`}
                                    </span>
                                </div>
                                {selectedAccount.gracePeriodFundTransfer.transferAccount === "" ?
                                    <div className="right-side" onClick={() => setFundTransfer(true)}>
                                        <img data-testid="grace-funds" src={bluePencil} alt="edit fund" />
                                        <span className="link-text regular">Add / withdraw funds</span>
                                    </div>
                                    :
                                    <div className="right-side" onClick={() => setOpenCancelFunds(true)}>
                                        <img data-testid="grace-funds" src={redTrashCan} alt="edit fund" />
                                        <span className="link-text regular remove">Cancel transfer</span>
                                    </div>
                                }
                            </div>
                            <div className="bottom-border" />
                            <span className="close-text regular" onClick={() => setCloseAccount(true)}>Close this account</span>
                            {/* <div className="close-wrapper">
                                <img data-testid="phone-chevron" className={!showPhoneNumber ? "close-chevron close" : "close-chevron open"} src={blueDownChevron} alt="blue downward facing chevron" onClick={() => setShowPhoneNumber(!showPhoneNumber)}/>
                                <span data-testid="phone-text" className="close-text regular" onClick={() => setShowPhoneNumber(!showPhoneNumber)}>Close this account</span>
                            </div>
                            {showPhoneNumber &&
                                <span className="phone-text regular">To close your account, please call us at&nbsp;<a className="blue-text regular" href={"tel:18776281610"}>877-628-1610</a>.</span>
                            } */}
                        </div>
                    </div>
                    <div className="maturity-link-wrap" onClick={() => history.push(`/account/${selectedAccount.id}/maturity`)}>
                        <div className="left-side">
                            <img src={maturityCalendar} alt="maturity calendar" />
                            <div className="column-stack">
                                <span className="header-text boldLarge">Looking for your maturity plan?</span>
                                <span className="sub-header-text regular">Choose what happens to your new CD when it matures by changing your maturity plan.</span>
                            </div>
                        </div>
                        <img src={greyRightArrow} alt="open link" />
                    </div>
                </>
                :
                <div className="grace-landing-loading-wrap">
                    <div className="loading-box top" />
                    <div className="loading-box bottom" />
                </div>
            }
        </>
    )
}

export default DesktopGraceLanding