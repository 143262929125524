import { useDesktopAccountsDropdown } from '../../../../../useHooks/componentHooks/useDesktopAccountsDropdown/useDesktopAccountsDropdown'
import downArrow from '../../../../../assets/downArrow.svg'
import './DesktopAccountsDropdown.scss'
import { IAccountInformation } from '../../../../../api/getAccountInformation'
import { setSelectedAccount } from '../../../../../slices/selectedAccountSlice/selectedAccountSlice'
import { IDesktopAccountsDropdownProps } from './interfaces/IDesktopAccountsDropdownProps'
import blueCheckmark from '../../../../../assets/blueCheckmark.svg'

/**
 * This component displays the filtering dropdown used in the desktop documents pages
 */
const DesktopAccountsDropdown = (props: IDesktopAccountsDropdownProps) => {
    const { accountInformation, openDropdown, setOpenDropdown, dispatch } = useDesktopAccountsDropdown()
    const { selectedAccount } = props

    return(
        <div className="accounts-dropdown-wrapper">
            <div className="label-wrap">
                <span className="dropdown-label small">Account</span>
            </div>
            <div className={openDropdown ? "desktop-dropdown-wrapper selected" : "desktop-dropdown-wrapper"} onClick={() => setOpenDropdown(!openDropdown)}>
                <div className="dropdown-left">
                    <span className="account-text regular">{selectedAccount.accountTitle} - {selectedAccount.accountType} {selectedAccount.accountNumber.slice(selectedAccount.accountNumber.length - 4, selectedAccount.accountNumber.length)}</span>
                </div>
                <div className='dropdown-right' >
                    <img src={downArrow} alt="Open dropdown menu" />
                </div>
            </div>
            {openDropdown && 
                <div className="desktop-document-dropdown-display">
                    {accountInformation.map((account: IAccountInformation, index: number) => {
                        return(
                            <div key={account.id}>
                            <div className="account-row" onClick={() => {dispatch(setSelectedAccount(account)); setOpenDropdown(false)}}>
                                <span className={account.accountNumber === selectedAccount.accountNumber ? "account-text regular selected" : "account-text regular"}>{account.accountTitle} - {account.accountType} {account.accountNumber.slice(account.accountNumber.length - 4, account.accountNumber.length)}</span>
                                {account.accountNumber === selectedAccount.accountNumber && <img className="icon" src={blueCheckmark} alt="selected account" />}
                            </div>
                            {index !== accountInformation.length &&
                                <div className="border-line" />
                            }
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}

export default DesktopAccountsDropdown