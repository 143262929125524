import { IAccountAgreements, downloadAccountAgreement } from '../../../api/getAccountInformation'
import './DesktopDocumentAccountAgreements.scss'
import { IDesktopDocumentAccountAgreementsProps } from './interfaces/IDesktopDcoumentAccountAgreementsProps'
import blueDocument from '../../../assets/blueDocument.svg'
// import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { useToastMessage } from '../../../Context/ToastContext/useToastContext'
import { useRef } from 'react'
import { downloadDocuments } from '../../../utils/downloadDocuments'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import warning from '../../../assets/warning.svg'
import emptyStatements from '../../../assets/emptyStatements.svg'
import { downloadSignedAgreement_API } from '../../../api/termsAndConditions'


/**THe component displays the Account Agreements page in the desktop version of the Documents Center */
const DesktopDocumentAccountAgreements = (props: IDesktopDocumentAccountAgreementsProps) => {
    const { selectedAccount } = props
    const gotAgreements = useAppSelector((state: RootState) => state.selectedAccount.gotAgreements)
    const { message, type, setToastMessage, resetToast } = useToastMessage()
    const downloadClickedRef = useRef<boolean>(false)

    /**handle the onClick event and downloads the account statement document */
    const handleStatementDownload = async (id: string) => {
        if(!downloadClickedRef.current){
            downloadClickedRef.current = true
            try{
                const response = await downloadSignedAgreement_API(selectedAccount.id, id)
                downloadDocuments(response.data, selectedAccount.accountNumber, "Agreement", "")
                downloadClickedRef.current = false
            }
            catch{
                console.log("FAILED TO DOWNLOAD THE ACCOUNT AGREEMENT")
                setToastMessage("ERROR", "Unable to download document. Please try again later")
                resetToast()
                downloadClickedRef.current = false
            }
        }
    }

    return(
        <div className="desktop-documents-account-agreements">
            {gotAgreements !== "Loading" ?
                <>
                {gotAgreements === "Success" ?
                    <>
                    {message !== "" && <ToastMessageDB type={type} message={message} />}
                    {selectedAccount.accountAgreements.length > 0 ?
                        <div className="display-rows">
                            {selectedAccount.accountAgreements.map((document: IAccountAgreements, index: number) => {
                                return(
                                    <div key={index} className="display-row">
                                        <div className="left-side" onClick={() => handleStatementDownload(document.id)}>
                                            <img className="document-image" src={blueDocument} alt="document" />
                                            <span className="document regular">{document.title}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div className="message-wrapper">
                            <img className="message-image" src={emptyStatements} alt="no notices" />
                            <span className="header-text h4">No notices to display</span>
                            <span className="sub-text regular">Future notices & other documents will appear here</span>
                        </div>
                    }
                    </>
                    :
                    <div className="message-wrapper">
                        <img className="message-image" src={warning} alt="unable to retreive notices" />
                        <span className="header-text h4">Unable to load data</span>
                        <span className="sub-text regular">Please wait a moment and try again.</span>
                    </div>
                }
                </>
                :
                <div className="loading-box" />
            }
        </div>
    )
}

export default DesktopDocumentAccountAgreements