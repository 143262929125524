import { IAccountInformation, IOwner, getAccountNumber_API } from "../api/getAccountInformation";
import { jsPDF } from "jspdf";
import { convertDateTommddyyyy, convertDateWithTime } from "./DateUtils";
import { displayCurrency } from "./displayCurrency";
import C1BLogo from '../assets/C1BLogo.jpg'
import signature from '../assets/signature.jpg'
import { dateLongHand } from "./dateLongHand";

/**An API call to get the account number to be used in the verificatoin document */
const getAccountNumber = async (id: string) => {
    try{
        const response = await getAccountNumber_API(id)
        return response.data.accountNumber
    }
    catch{
        return ""
    }
}

/**Generates the verfication letter PDF */
export const generateVerificationPDF = async (selectedAccount: IAccountInformation, userInfo: any) => {
    let accountNumber = await getAccountNumber(selectedAccount.id)

    if(accountNumber === ""){
        return null
    }

    const newDate = new Date().toLocaleString()
    const doc = new jsPDF()
    let y = 5
    
    /**Checks the length of the text line, adds a new line when necessary */
    const checkStringLength = (line: string) => {
        if(line.length > 82){
            const LINES = line.split(" ")
            let LINE_ONE = LINES[0] + " "
            let LINE_TWO = ""
            let startNextLine = false
            for(let i = 1; i < LINES.length; i = i + 1){
                if((LINE_ONE.length + 1) + LINES[i].length < 86 && !startNextLine){
                    LINE_ONE = LINE_ONE + LINES[i] + " "
                }
                else{
                    startNextLine = true
                    LINE_TWO = LINE_TWO + LINES[i] + " "
                }
            }
            return [LINE_ONE, LINE_TWO]
        }
        return []
    }

    /**Formats and prints the text line. */
    const printLine = (line: string, coords = {x: 15, y: 5}, increment_y = true) => {
        if(line.length <= 82){
            doc.text(line, coords.x, y)
            if(increment_y){
                y = y +  coords.y
            }
        }
        else{
            const LINES: Array<string> = checkStringLength(line)
            if(LINES.length > 0){
                doc.text(LINES[0], coords.x, y)
                y = y + 5
                doc.text(LINES[1], coords.x, y)
                y = y + coords.y
            }
        }
    }

    /**Formats and prints the co-owners horizontally */
    const formatCoOwners = (owners: Array<IOwner>) => {
        let LINE = "•    "
        // LINE = LINE + "Chuck Norris, Rocky Balboa, Captain America, Harry Potter, "
        for(let i = 0; i < owners.length; i = i + 1){
            if(i !== owners.length - 1){
                LINE = LINE + owners[i].name + ", "
            }
            else{
                LINE = LINE + owners[i].name
            }
        }
        if(LINE.length <= 82){
            printLine(LINE, {x: 20, y: 9})
        }
        else{
            const LINES: Array<string> = checkStringLength(LINE)
            if(LINES.length > 0){
                doc.text(LINES[0], 20, y)
                y = y + 5
                doc.text(LINES[1], 27, y)
                y = y + 9
            }
        }
    }
    
    doc.setFontSize(13);
    doc.addImage(`${C1BLogo}`, "JPEG", 15, y, 48, 20);
    y = y + 30
    printLine("Credit One Bank, N.A.")
    printLine("P.O. Box 98873")
    printLine("Las Vegas, NV 89193-8873", {x: 15, y: 9})
    printLine(`${userInfo.firstName} ${userInfo.lastName}`)
    printLine(`${userInfo.contactInformation.addresses[0].addressLine1}`)
    
    if(userInfo?.contactInformation?.addresses[0]?.addressLine2) {
        printLine(`${userInfo.contactInformation.addresses[0].addressLine2}`)
    }

    printLine(`${userInfo.contactInformation.addresses[0].city} ${userInfo.contactInformation.addresses[0].state}, ${userInfo.contactInformation.addresses[0].zipCode}`, {x: 15, y: 9})
    printLine(`${dateLongHand(convertDateTommddyyyy(new Date (newDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})))}`, {x: 15, y: 9})
    printLine(`Re: Account Verification Letter for Credit One Bank ${selectedAccount.description} ${accountNumber}`, {x: 15, y: 9})
    printLine("To Whom it May Concern:", {x: 15, y: 9})
    printLine(`Please accept this letter as verification that ${userInfo.firstName} ${userInfo.lastName} has the following account with Credit One Bank:`, {x: 15, y: 9})
    printLine("•    Product:", {x: 20, y: 5}, false)
    printLine(`${selectedAccount.description}`, {x: 88, y: 5})
    printLine("•    Account Number:", {x: 20, y: 5}, false)
    printLine(`${accountNumber}`, {x: 88, y: 5})
    printLine("•    Account Open Date:", {x: 20, y: 5}, false)
    printLine(`${convertDateTommddyyyy(new Date(selectedAccount.creationDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"}), true)}`, {x: 88, y: 5}) 
    printLine("•    Current Account Balance:", {x: 20, y: 5}, false)
    printLine(`${displayCurrency(+selectedAccount.currentBalance)}`, {x: 88, y: 5})
    printLine("•    Available Account Balance:", {x: 20, y: 5}, false)
    printLine(`${displayCurrency(+selectedAccount.balance)}`, {x: 88, y: 5})

    if(selectedAccount.accountType !== "Savings"){
        printLine("•    Maturity Date:", {x: 20, y: 5}, false)
        printLine(`${convertDateWithTime(selectedAccount.maturityDate, false)}`, {x: 88, y: 9})
    }
    else{
        y = y + 9;
    }

    if(selectedAccount.owner.length > 1){
        printLine(`This ${selectedAccount.description} is jointly owned with the following account owners:`, {x: 15, y: 9})
        formatCoOwners(selectedAccount.owner)
    }
    if(selectedAccount.accountType !== "Savings"){
        printLine("This account is subject to the terms and conditions contained in the account")
        printLine("agreement and disclosures. The account is Non-Negotiable and Non-Transferable.")
        printLine("Withdrawal of the deposit amount partially or in full, prior to the maturity date")
        printLine("may incur early withdrawal penalties.", {x: 15, y: 9})
    }
    else{
        printLine("This account is subject to the terms and conditions contained in the account ")
        printLine("agreement and disclosures. The account is Non-Negotiable and Non-Transferable.", {x: 15, y: 9})
    }
    printLine("If you have any questions, please contact our Senior Account Specialists at ")
    printLine("1-877-628-1610 between 7:00am and 5:00pm Monday through Friday (Pacific time).", {x: 15, y: 9})
    printLine("Thank you for choosing Credit One Bank. Member FDIC.", {x: 15, y: 9})
    printLine("Sincerely,", {x: 15, y: 2})
    doc.addImage(`${signature}`, "JPEG", 15, y, 48, 18);
    y = y + 27;
    printLine("David J. Herpers")
    printLine("SVP, Digital Banking")
    printLine("Credit One Bank, N.A.")
    printLine("Las Vegas, NV")


    doc.save("verification_letter.pdf");
}