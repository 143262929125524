import { useEffect, useRef, useState } from "react"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { setCheckInternalToken, setOTPPassed } from "../../../slices/userInformation/userInformationSlice"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { changePassword_API } from "../../../api/User/changePassword"
import { useUserProfileContext } from "../../../Pages/UserProfile/Context/useUserProfileContext"

/**A custom use hook holding the logic for the Change Password component found in the user profile flow */
export const useChangePassword = (setChangePassword: any) => {
    const { windowState } = useWindowState()
    const { currentPassword, setCurrentPassword, newPassword, setNewPassword, retypedPassword, setRetypedPassword,
            currentPasswordRef, newPasswordRef, retypedPasswordRef, showRetypeError, setShowRetypeError, ShowCurrentPasswordError,
            setShowCurrentPasswordError, lettersPassed, setLettersPassed, numberPassed, setNumberPassed, specialPassed,
            setSpecialPassed, lengthPassed, setLengthPassed } = useUserProfileContext()
    const [enableSave, setEnableSave] = useState<boolean>(false)
    const [showCurrent, setShowCurrent] = useState<boolean>(false)
    const [showNew, setShowNew] = useState<boolean>(false)
    const [showRetyped, setShowRetyped] = useState<boolean>(false)
    const [requirementsMet, setRequirmentsMet] = useState<boolean>(false)
    let validationArray = [lettersPassed, numberPassed, specialPassed, lengthPassed]
    const handleSaveClickedRef = useRef<boolean>(false)
    const dispatch = useAppDispatch()
    const OTPPassed = useAppSelector((state: RootState) => state.userInformation.OTPPassed)
    const { type, message, setToastMessage, resetToast } = useToastMessage()
    const apiStatusRef = useRef<true | false | null>(null)

    /**Check to see if password requirements are met */
    useEffect(() => {
        if(lettersPassed && numberPassed && specialPassed && lengthPassed){
            setRequirmentsMet(true)
        }
        else{
            setRequirmentsMet(false)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lettersPassed, numberPassed, specialPassed, lengthPassed, retypedPassword, newPassword])

    useEffect(() => {
        if(retypedPasswordRef.current === newPasswordRef.current && requirementsMet){
            setEnableSave(true)
        }
        else{
            setEnableSave(false)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retypedPassword, newPassword, requirementsMet])

    /**Handles the onChange event for current password and handles the mask*/
    const handleCurrentPassword = (e: any) => {
        currentPasswordRef.current = e.target.value
        setCurrentPassword(e.target.value)
        if(retypedPasswordRef.current === newPasswordRef.current && requirementsMet){
            setEnableSave(true)
        }
        else{
            setEnableSave(false)
        }
    }

    /**Handles the onChange event for the new password and checks the requirements, and handles the mask*/
    const handleNewPassword = (e: any) => {
        newPasswordRef.current = e.target.value
        setNewPassword(e.target.value)

        /**Reg Ex experessions for check requirements */
        let patternLower = new RegExp("^(?=.*[a-z]).+$")
        let patternUpper = new RegExp("^(?=.*[A-Z]).+$")
        let patternNumber = new RegExp("^(?=.*\\d).+$")
        let patternSpecial = new RegExp("^(?=.*[-+_!@#$%^&*.,?]).+$")

        /**Check requirements */
        setLettersPassed(patternLower.test(newPasswordRef.current) && patternUpper.test(newPasswordRef.current))
        setNumberPassed(patternNumber.test(newPasswordRef.current))
        setSpecialPassed(patternSpecial.test(newPasswordRef.current))
        setLengthPassed(newPasswordRef.current.length >= 8)
    }

    /**Gets the total number of new password validations that have satisfied the requirements */
    const checkTotalPassed = () => {
        let tempValue = 0
        for(let i = 0; i < validationArray.length; i = i + 1){
            if(validationArray[i]){
                tempValue = tempValue + 1
            }
        }
        return tempValue
    }

    /**Handles the onChange event for the retyped password, and handles the mask */
    const handleRetypedPassword = (e: any) => {
        retypedPasswordRef.current = e.target.value
        setRetypedPassword(e.target.value)
        if(retypedPasswordRef.current === newPasswordRef.current){
            setShowRetypeError(false)
        }
        if(retypedPasswordRef.current === newPasswordRef.current && requirementsMet){
            setEnableSave(true)
        }
        else{
            setEnableSave(false)
        }
    }

    /**Handles retype password on blur, compares the retyped password to the new password */
    const handleRetypedOnBlur = () => {
        if(retypedPasswordRef.current === newPasswordRef.current){
            setShowRetypeError(false)
        }
        else{
            setShowRetypeError(true)
        }
    }

    /**Handles the onClick event for the Save button */
    const handleSave = async () => {
        if(!handleSaveClickedRef.current){
            dispatch(setOTPPassed(false))
            handleSaveClickedRef.current = true
            dispatch(setCheckInternalToken(true))
            return new Promise((resolve, reject) => {
                let interval: any
                interval = setInterval(() => {
                    if(apiStatusRef.current === false){
                        apiStatusRef.current = null
                        resolve(true)
                        clearInterval(interval)
                    }
                }, 100)
            })
        }
    }

    /**useEffect monitors the OTP, if successful we call the function to handle services */
    useEffect(() => {
        if(OTPPassed && handleSaveClickedRef.current){
            handleServiceCalls()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPPassed])

    /**handles the service calls for the change password page */
    const handleServiceCalls = async () => {
        apiStatusRef.current = true
        try{
            await changePassword_API(currentPasswordRef.current, newPasswordRef.current)
            setToastMessage("SUCCESS", "Password changed")
            setChangePassword(false)
            resetAllValues()
        }
        catch(error: any){
       
            let errorReturn: any = error.response ? JSON.parse(JSON.stringify(error.response)) : ""
            if(errorReturn !== "" && errorReturn.data.errorMsg[0] === "{title=Update of credentials failed, message=Old Password is not correct}"){
                setShowCurrentPasswordError(true)
            }
            else{
                setShowCurrentPasswordError(false)
                setToastMessage("ERROR", "Unable to save changes. Please try again later")
                resetToast()
            }
        }
        apiStatusRef.current = false
        handleSaveClickedRef.current = false
    }

    const resetAllValues = () => {
        setCurrentPassword("")
        setNewPassword("")
        setRetypedPassword("")
        currentPasswordRef.current = ""
        newPasswordRef.current = ""
        retypedPasswordRef.current = ""
        setShowRetypeError(false)
        setShowCurrentPasswordError(false)
        setLettersPassed(false)
        setNumberPassed(false)
        setSpecialPassed(false)
        setLengthPassed(false)
    }

    return { windowState, currentPassword, handleCurrentPassword, newPassword, handleNewPassword, retypedPassword, handleRetypedPassword,
             enableSave, showCurrent, setShowCurrent, showNew, setShowNew, showRetyped, setShowRetyped, lettersPassed, numberPassed,
             specialPassed, lengthPassed, checkTotalPassed, handleSave, type, message, ShowCurrentPasswordError, handleRetypedOnBlur, showRetypeError, resetAllValues }
}