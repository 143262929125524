import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"
import { getEventPath } from "../../utils/getEventPath"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { deleteExternalAccount_API, validateMicroTransactions_API } from "../../api/User/LinkExternalAccounts"
import { useToastMessage } from "../../Context/ToastContext/useToastContext"
import { IExternalAccounts } from "../../api/User/interfaces"
import { removeExternalAccountByIndex, selectedLinkAccount, updateExternalAccountStatusByIndex } from "../../slices/userInformation/userInformationSlice"
import { IEDPErrorInterface, edpErrorReporting_API } from "../../api/edpErrorReporting"

/**A custom use hook holding the logic for the verify extrnal account pages */
export const useVerifyExternalAccount = () => {
    const history = useHistory()
    const [showModal, setShowModal] = useState<boolean>(false)
    const [enableVerify, setEnableVerify] = useState<boolean>(false)
    const valueOneRef = useRef<string>("")
    const valueTwoRef = useRef<string>("")
    const [valueOne, setValueOne] = useState<string>("")
    const [valueTwo, setValueTwo] = useState<string>("")
    const valueOneErrorRef = useRef<boolean>(true)
    const valueTwoErrorRef = useRef<boolean>(true)
    const selectedExternalAccount = useAppSelector((state: RootState) => state.userInformation.selectedExternalAccount)
    const verifySelectedRef = useRef<boolean>(false)
    const { type, message, setToastMessage, resetToast } = useToastMessage()
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const dispatch = useAppDispatch()
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [showLocked, setShowLocked] = useState<boolean>(false)

    /**repopulate the selected external account information on page refresh */
    useEffect(() => {
        if (userInformation.gotExternalAccounts && selectedExternalAccount.id === "") {
            const adjustedUrl = window.location.pathname.split('/')[1]
            const index = userInformation.externalAccounts.findIndex((account: IExternalAccounts) => account.id === adjustedUrl)
            if (index >= 0) {
                dispatch(selectedLinkAccount(userInformation.externalAccounts[index]))
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation, selectedExternalAccount])

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if (Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("modal-card")) === -1) {
                document.body.style.overflow = "auto"
                setShowModal(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**lock the scroll bar when the modal is open */
    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = "hidden"
        }
        else {
            document.body.style.overflow = "auto"
        }

    }, [showModal])

    /**Checks the account and re-enter account inputs for an error */
    const checkAccountInputError = () => {
        if (!valueOneErrorRef.current && !valueTwoErrorRef.current) {
            setEnableVerify(true)
        }
        else {
            setEnableVerify(false)
        }
    }

    /**handles the onKeyDown event for the account number and re-enter acccount number inputs */
    const handleAccountKeyDown = (e: any) => {
        if (e.key === "+" || e.key === "-" || e.key === "e") {
            e.preventDefault()
        }

        if (e.key === 'Tab' || (e.key === 'a' && e.ctrlKey)) {
            return;
        }


        const DECIMAL_SPLIT = e.target.value.split('.')[1]
        if (+e.target.value > .99 && /\d/.test(e.code) || DECIMAL_SPLIT && DECIMAL_SPLIT.length >= 2 && e.code !== 'Backspace') {
            e.preventDefault();
        }
    }

    /**handles the onChange event for the first depost amount input */
    const handleValueOne = (e: any) => {

        if (+e.target.value <= 0) {
            setValueOne("")
            valueOneRef.current = "";
            valueOneErrorRef.current = true
            checkAccountInputError()
            return;
        }
        valueOneRef.current = e.target.value
        setValueOne(e.target.value)
        if (valueOneRef.current !== "") {
            valueOneErrorRef.current = false
        }
        else {
            valueOneErrorRef.current = true
        }
        checkAccountInputError()
    }

    /**handles the onChange event for the second deposit amount input */
    const handleValueTwo = (e: any) => {
        if (+e.target.value <= 0 || !e.target.value) {
            setValueTwo("")
            valueTwoRef.current = ''
            valueTwoErrorRef.current = true;
            checkAccountInputError()
            return;
        }

        valueTwoRef.current = e.target.value
        setValueTwo(e.target.value)
        if (valueTwoRef.current !== "") {
            valueTwoErrorRef.current = false
        }
        else {
            valueTwoErrorRef.current = true
        }
        checkAccountInputError()
    }

    /**handles the verify deposits button click */
    const handleVerifyDeposits = async () => {
        if (!verifySelectedRef.current) {
            setShowError(false)
            verifySelectedRef.current = true
            try {
                await validateMicroTransactions_API(selectedExternalAccount.id, valueOne, valueTwo)

                const index = userInformation.externalAccounts.findIndex((account: IExternalAccounts) => account.id === selectedExternalAccount.id)
                const payload = { arrayIndex: index, status: "Linked" }
                dispatch(updateExternalAccountStatusByIndex(payload))
                verifySelectedRef.current = false
                setShowSuccess(true)
            }
            catch (error: any) {
                const errorMessage = error.response ? JSON.parse(JSON.stringify(error.response)) : ""

                if (errorMessage !== "" && errorMessage.data.errorMsg.length && (errorMessage.data.errorMsg[0] === "micro deposit is already validated" || errorMessage.data.errorMsg[0] === "Internal server error")) {
                    setToastMessage("ERROR", "Unable to save changes. Please try again later")
                    resetToast()
                }
                else if (errorMessage !== "" && errorMessage.data.errorMsg.length && errorMessage.data.errorMsg[0] === "Amount1 and Amount2 should be greater than 0.00 and less than 1.01"){
                    setShowError(true)
                }
                else if (errorMessage !== "" && errorMessage.data.errorMsg.length && errorMessage.data.errorMsg[0] === "micro deposits amounts not matched") {
                    setShowError(true)
                }
                else if (errorMessage !== "" && errorMessage.data.errorMsg.length && (errorMessage.data.errorMsg[0] === "micro deposit is locked" || errorMessage.data.errorMsg[0] === "VALIDATION_FAILED")) {
                    try {
                        await deleteExternalAccount_API(selectedExternalAccount.id)
                        const index = userInformation.externalAccounts.findIndex((account: IExternalAccounts) => account.id === selectedExternalAccount.id)
                        dispatch(removeExternalAccountByIndex(index))
                        setShowLocked(true)
                    }
                    catch {
                        console.log("ERROR DELETING THE FAILED LINKED EXTERNAL ACCOUNT")
                    }
                }
                else {
                    let errorReturn: any = error.response ? JSON.parse(JSON.stringify(error.response)) : ""
                    let edpErrorObject: IEDPErrorInterface = {
                        category: "External_Account",
                        errorType: "API_error",
                        errorMessage: [{message: errorReturn?.data?.errorMsg[0] || ""}, {message: "VERIFY YOUR ACCOUNT"}],
                        timeStamp: new Date().toISOString(),
                        additionalDetails: {
                            externalAccounts: [{
                                acctNo: selectedExternalAccount.lastFour,
                                acctId: selectedExternalAccount.id,
                                routingNo: selectedExternalAccount.routingNumber,
                                bankName: selectedExternalAccount.accountNickName !== "" ? selectedExternalAccount.accountNickName : selectedExternalAccount.description || "",
                                errorCode: errorReturn.status || "",
                                verificationMethod: "MD"
                            }]        
                        }
                    }
                    try{
                        edpErrorReporting_API(edpErrorObject)
                    }
                    catch{}      
    
                    setToastMessage("ERROR", "An error has occurred. Please try again later")
                    resetToast()
                }
                verifySelectedRef.current = false
            }
        }
    }

    const handleReturn = () => {
        setShowError(false)
        setShowSuccess(false)
    }

    return {
        history, showModal, setShowModal, enableVerify, handleAccountKeyDown, handleValueOne, handleValueTwo, valueOne,
        valueTwo, handleVerifyDeposits, type, message, showSuccess, showError, selectedExternalAccount, handleReturn, showLocked
    }
}