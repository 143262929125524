import { IOwner } from '../../../api/getAccountInformation'
import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import { IMobileAccountOwnershipProps } from './interfaces/IMobileAccountOwnershipProps'
import './MobileAccountOwnership.scss'
import AddOwnerForm from '../components/AddOwnerForm/AddOwnerForm'
import ComplianceQuestions from '../components/ComplianceQuestions/ComplianceQuestions'
import ReviewOwner from '../components/ReviewOwner/ReviewOwner'
import OwnerConfirmation from '../components/OwnerConfirmation/OwnerConfirmation'
import arrowLeftBlue from '../../../assets/arrowLeftBlue.svg'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'

/**
 * The component displays the mobile version of the Account Ownership page.  This is the page
 * that renders when there is an account owner plus co-owners.  The page allows for new
 * co-owners to be added, and current account owners to be viewed.
 */
const MobileAccountOwnership = (props: IMobileAccountOwnershipProps) => {
    const { addOwner, setAddOwner, selectedAccount, formStep, setFormStep, form, dispatchForm,
            IRSWithholding, setIRSWithholding, foreignAffiliation, setForeignAffiliation, usingSuggestion, setUsingSuggestion } = props
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

    /**Handles the onclick event for the Cancel button */
    const handleCancel = () => {
        setAddOwner(false)
        setFormStep(1)
        form.resetForm()
        dispatchForm()
        setIRSWithholding("")
        setForeignAffiliation("")
    }      

    return (
        <>
        {!addOwner ?
            <div className="mobile-account-ownership-wrapper">
                <div>
                    <div className="mobile-ownership-header-wrap">
                        <AccountHeader returnMessage={'Account Settings'} setDisplayDetails={false} />
                    </div>
                    {isAccountDetailsLoaded !== "Loading" ?
                        <div className="mobile-ownership-card-wrap">
                            <div className="header-wrap">
                                <span className="header-text h3">Account ownership</span>
                                <span className="info-text small">You may add up to ten co-owners to your account.</span>
                            </div>
                            <div className="column-headers">
                                <span className="header-text x-small">Name</span>
                                <span className="header-text x-small">Role</span>
                            </div>
                            {selectedAccount.owner?.map((owner: IOwner, index: number) => {
                                return (
                                    <div key={owner.name} className="row-wrap">
                                        <span className="owner-name small">{owner.name}</span>
                                        <span className="owner-role boldSmall">{owner.role}</span>
                                    </div>
                                )
                            })}
                            <span className="help-text small">New co-owner requests can take up to 7 business days. For status, give us a call at <a href="tel: 877-628-1610" className="blue-text">877-628-1610.</a></span>
                            {selectedAccount.owner.length < 10 && <button className="Button btn-primary" onClick={() => setAddOwner(true)}>Add a co-owner</button>}
                        </div>
                        :
                        <div className="loading-box" />
                    }
                </div>
            </div>
            :
            <>
                {formStep !== 4 ?
                    <>
                        <div className="owner-mobile-nav-wrap">
                            {formStep > 1 ?
                                <div className='left-side'>
                                    <img src={arrowLeftBlue} alt="left facing blue chevron" />
                                    <span className="blue-text regular" onClick={() => setFormStep(formStep - 1)}>Back</span>
                                </div>
                                :
                                <div />    
                            }
                            <span className="blue-text regular" onClick={() => handleCancel()}>Cancel</span>
                        </div>
                    </>
                    :
                    <div className="owner-mobile-nav-wrap last" />    
                }
                <div className={formStep === 4 ? "owner-card-wrapper" : "owner-card-wrapper progress-bar"}>
                    {formStep !== 4 &&
                        <>
                        <div className={`progress-bar-step step-${formStep}`} />
                        <div className="status-wrap">
                            <span className="step-status regular">Add a co-owner</span>
                            <span className="step-status small">Step {formStep} of 3</span>
                        </div>
                        </>
                    }
                    {formStep === 1 &&
                        <AddOwnerForm 
                            dispatchForm={dispatchForm}
                            form={form}
                            setAddOwner={setAddOwner}
                            setFormStep={setFormStep}
                            usingSuggestion={usingSuggestion}
                            setUsingSuggestion={setUsingSuggestion}
                        />
                    }
                    {formStep === 2 &&
                        <ComplianceQuestions 
                            form={form}
                            dispatchForm={dispatchForm}
                            IRSWithholding={IRSWithholding} 
                            setIRSWithholding={setIRSWithholding}
                            foreignAffiliation={foreignAffiliation}
                            setForeignAffiliation={setForeignAffiliation}
                            setFormStep={setFormStep}
                            setAddOwner={setAddOwner}
                        />
                    }
                    {formStep === 3 &&
                        <ReviewOwner 
                            form={form} 
                            dispatchForm={dispatchForm}
                            IRSWithholding={IRSWithholding}
                            setIRSWithholding={setIRSWithholding}
                            foreignAffiliation={foreignAffiliation}
                            setForeignAffiliation={setForeignAffiliation}
                            setFormStep={setFormStep}
                            setAddOwner={setAddOwner}
                        />
                    }
                    {formStep === 4 &&
                        <OwnerConfirmation 
                            form={form}
                            dispatchForm={dispatchForm}
                            setFormStep={setFormStep}
                            setAddOwner={setAddOwner}
                        />
                    }            
                </div>
            </>
        }
        </>
    )
}

export default MobileAccountOwnership
