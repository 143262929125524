import { IBeneficiary } from "../../api/getBeneficiaries"
import { ITransactionHistoryDataMock } from "../../api/getTransactionHistory"
import { IVerifiedAccount, IYodleeVerifiedAccountResponse } from '../../api/getYodleeVerifiedAccounts'
import { ExternalBankAccounts, OwnershipDetails, Transfer } from '../../api/models'
import { ResponseStatus } from '../../api/models/response-status'
import { IUser } from "../../api/User/interfaces"
import { INotificationServiceObject } from "../../shared/common/Notification/interfaces/INotifcationProps.types"

import {
    IAccountDetailsExtended, IExternalInterestSettings, IInternalInterestSettings, IDocument,
    TDocumentType, IDocumentExtended, IUserExtended, ICDAccountDetailsExtended, ISavingsAccountDetailsExtended,
    IGracePeriodCDAccountDetailsExtended, ITransferInformation, ITransferAccount, ITransferDetails
} from "./interfaces/IMockAPIInterfaces"
import { YodleeController } from './MockDigitalBankAPIs/contextControllers/YodleeController'

export class MockDigitalBankAPIs {
    user: IUserExtended = this.addUser();
    accounts = new Map<string, IAccountDetailsExtended>()
    transactions = new Map<string, Array<ITransactionHistoryDataMock>>()
    externalaccounts = new Map<string, ExternalBankAccounts>()
    documents = new Map<string, Array<IDocumentExtended>>();
    taxDocuments: Array<IDocumentExtended> = [];
    notifications: Array<INotificationServiceObject> = [];
    transferHistory: Array<ITransferInformation> = [];
    scheduledTransfers: Array<ITransferInformation> = [];
    yodlee = new YodleeController();

    /**constructor used when the class is created, takes an argument "overload" of type IAccountInformation */
    constructor(overload: Partial<IUser> = {}) {
        this.addUser(overload as Partial<IUser>)
    }

    /**Creates a user account, returns the NEW_USER, unless overload is proivded, then returns NEW_USER + overload modification 
     * @param overload - Any overloads to the default that needs to be added.
     * @returns - The newly created user
    */
    addUser(overload: Partial<IUserExtended> = {}) {
        const NEW_USER: IUserExtended = {
            "customerId": "cebe501b-aebc-5b72-bf84-b8dd99d905b5",
            "creationDate": "2023-03-16T21:46:29.295+00:00",
            "nickName": "Diilio",
            trustedDevices: [],
            "name": "Duilio Ninteman",
            "email": {
                "individualEmailAddressId": "2efc2a90-ed6d-42b3-9008-2bb8fd7f0bfc",
                "emailAddress": "Tapodip.sinha@creditone.com",
                "emailUsageType": "PERSONAL",
                "emailVerifiedFlag": true
            },
            "phones": [
                {
                    "individualPhoneId": "830def58-9b47-4c99-a241-225176ae65b7",
                    "phoneNumber": "7257242264",
                    "phoneUsageType": "Primary",
                    "phoneVerifiedFlag": true
                }
            ],
            "addresses": [
                {
                    "individualAddressId": "a0a61ff2-bc84-44db-b715-3fe14230c8c4",
                    "addressLine1": "3456 Willson Square",
                    "addressLine2": "1081",
                    "city": "Las Vegas",
                    "state": "NV",
                    "zipcodeFirst5": "89169",
                    "addressUsageType": "ALTERNATE",
                    "addressVerifiedFlag": true,
                    "isMailing": true
                }
            ],
            subscription_status: 'opt_In',
            isTaxWithholding: false
        }

        this.user = { ...NEW_USER, ...overload }
        return this.user
    }

    /**
     * Creates a CD Account
     * @param overload - Any overloads to the default that needs to be added.
     * @returns - The newly created account
     */
    addCDAccount(overload: Partial<Omit<ICDAccountDetailsExtended, 'accountType'>> = {}) {
        const NEW_ACCOUNT: ICDAccountDetailsExtended = {
            ...this.initAccount(),
            ...overload,
            accountType: 'CD'
        }

        return this.addAccount(NEW_ACCOUNT)
    }

    /**
  * Creates a Savings Account
  * @param overload - Any overloads to the default that needs to be added.
  * @returns - The newly created account
  */
    addSavingsAccount(overload: Partial<Omit<ISavingsAccountDetailsExtended, 'accountType'>> = {}) {
        const NEW_ACCOUNT: ISavingsAccountDetailsExtended = {
            ...this.initAccount(),
            ...overload,
            accountType: 'Savings',
            isInGracePeriod: false,
        }

        this.addAccount(NEW_ACCOUNT)
    }

    /**
  * Creates a CD in grace period Account
  * @param overload - Any overloads to the default that needs to be added.
  * @returns - The newly created account
  */
    addGracePeriodCDAccount(overload: Partial<Omit<IGracePeriodCDAccountDetailsExtended, 'accountType'>> = {}) {
        const NEW_ACCOUNT: ICDAccountDetailsExtended = {
            ...this.initAccount(),
            ...overload,
            accountType: 'CD',
            isInGracePeriod: true,
            // accountTitle: "Grace Account",
            lastRollOverDate: "2023-09-14T07:00Z",
            creditGracePeriod: "2023-09-23T16:39:25Z"
            // graceStartDate: "2023-09-14T07:00Z",
            // graceEndDate: '2023-09-23T16:39:25Z',
        }

        this.addAccount(NEW_ACCOUNT)
    }

    /**
 * Creates a Bump Rate CD
 * @param overload - Any overloads to the default that needs to be added.
 * @returns - The newly created account
 */
    addBumpRateCDAccount(overload: Partial<Omit<ICDAccountDetailsExtended, 'accountType' | 'prouductGroup'>> = {}, bumpEnabled = false) {
        const NEW_ACCOUNT: ICDAccountDetailsExtended = {
            ...this.initAccount(),
            ...overload,
            accountType: 'CD',
            productGroup: 'BumpupCD',
            isBumpAvailable: overload.isBumpAvailable === undefined ? !bumpEnabled : overload.isBumpAvailable,
            bumpEnabled: overload.isBumpAvailable === undefined ? !bumpEnabled : overload.isBumpAvailable,
            isBumpEnabled: overload.isBumpEnabled === undefined ? !bumpEnabled : overload.isBumpEnabled,
            originalApy: overload.originalApy === undefined ? 1 : overload.originalApy,
            bumpApy: overload.bumpApy === undefined ? 10 : overload.bumpApy,
        }

        this.addAccount(NEW_ACCOUNT)
    }

    /**
     * Creates a Bump Rate CD
     * @param overload - Any overloads to the default that needs to be added.
     * @returns - The newly created account
     */
    addBumpRateCDAccountWithBumpRateEnabled(overload: Partial<Omit<ICDAccountDetailsExtended, 'accountType' | 'prouductGroup'>> = {}, bumpEnabled = true) {
        const NEW_ACCOUNT: ICDAccountDetailsExtended = {
            ...this.initAccount(),
            ...overload,
            accountType: 'CD',
            productGroup: 'BumpupCD',
            isBumpAvailable: overload.isBumpAvailable === undefined ? !bumpEnabled : overload.isBumpAvailable,
            bumpEnabled: overload.isBumpAvailable === undefined ? !bumpEnabled : overload.isBumpAvailable,
            isBumpEnabled: overload.isBumpEnabled === undefined ? !bumpEnabled : overload.isBumpEnabled,
            originalApy: overload.originalApy === undefined ? 1 : overload.originalApy,
            bumpApy: overload.bumpApy === undefined ? 10 : overload.bumpApy,
        }

        this.addAccount(NEW_ACCOUNT)
    }

    /**
      * Creates a CD Account
      * @param overload - Any overloads to the default that needs to be added.
      * @returns - The newly created account
      */
    initAccount(): IAccountDetailsExtended {
        return {
            accountId: crypto.getRandomValues(new Uint32Array(5)).toString(),
            accountType: "CD",
            accountNumber: "123456",
            accountName: "Jumbo 6-Month CD",
            accountNickName: "Go Blue",
            accountTitle: "Go Blue",
            availableBalance: 100000,
            currentBalance: 100000,
            apy: 2.50,
            maturitySettings: {
                matureOn: "2023-09-14T07:00:00Z",
                option: "Capitalize Interest and rollover",
                termLength: "6",
            },
            routingNumber: "123456789",
            ownershipDetails: [
                { name: "Jim Harbaugh", role: "Owner" },
                { name: "Stone Cold Steve Austin", role: "Co-owner" },
            ],
            interestPaidAccountToDate: 1000,
            interestPaidYTD: 1000,
            interestPaidPriorYear: 1000,
            beneficiaries: [],
            createdOn: "",
            bumpEnabled: false,
            lastStatementDate: "",
            originalApy: 0,
            ownership: "2023-03-14T16:37:20.925547Z",
            rollOverMaturity: {
                changeProduct: "6 Month",
                counterPartyAcctNumber: "",
                orderAmt: 0,
                productCode: "6M_CD_STAND",
                rollMaturityOpt: "",
                rollPlan: "Renew",
                transferType: "",
                productGroup: "JumboCD"
            },
            status: "Open",
            closedDate: undefined,
            productName: '6M_CD_STAND',
            productGroup: 'JumboCD',
            interestSettings: {},
            termsAndCondition: {
                "isSigned": true,
                "signedBy": "DUILIO NINTEMAN",
                "signedDate": "2023-08-23T20:00:39.139984Z",
                "docType": "1"
            },
            earlyWithdrawal: undefined,
            earlyWithdrawalSelected: false,
            isInGracePeriod: false,
            penaltyAmount: "0",
            rewardActive: false,
        }
    }

    /**Add an additional address */
    addAlternateAddress() {
        this.user.addresses?.push(
            {
                "individualAddressId": "71981298-c3eb-4fee-bec8-f7ff0bd5c5d1",
                "addressLine1": "5639 Bettors Luck Ct",
                "addressLine2": "Apt A",
                "city": "Las Vegas",
                "state": "NV",
                "zipcodeFirst5": "89122",
                "addressUsageType": "Residence",
                "addressVerifiedFlag": true,
                "isMailing": false
            }
        )
    }

    /**Add an additional phone number */
    addAlternatePhoneNumber() {
        this.user.phones?.push(
            {
                "individualPhoneId": "a1fcb2da-b666-4373-85b1-8cdc5f74b71a",
                "phoneNumber": "2353453453",
                "phoneUsageType": "Other",
                "phoneVerifiedFlag": true,
            }
        )
    }

    /**Adds required consent to particular accounts */
    addRequiredConsent(accountID: string) {
        let account = this.accounts.get(accountID)
        if (account) {
            account.termsAndCondition = {}
            account.termsAndCondition.isSigned = false
            account.productGroup = "JumboCD"
            account.term = "6M"
            account.createdDate = "2023-03-23T06:22:08Z"
            account.apy = 5.2
        }

        return account
    }

    /**Adds close account selection to maturity plan rollover */
    addCloseMaturity = (accountID: string) => {
        let account = this.accounts.get(accountID)
        if (account) {
            account.rollOverMaturity = {}
            account.rollOverMaturity.changeProduct = ""
            account.rollOverMaturity.transferType = "Withdraw"
            account.rollOverMaturity.counterPartyAcctNumber = "1776"
            account.rollOverMaturity.rollMaturityOpt = "Transfer principal and interest"
            account.rollOverMaturity.bankName = "Credit One Bank, Na"
            account.rollOverMaturity.rollPlan = "Close"
        }

        return account
    }

    /**Adds a maturity plan transfer object to account details  */
    addMaturityPlanTransfer = (accountID: string) => {
        let account = this.accounts.get(accountID)
        if (account) {
            account.transfer = {}
            if (account.transfer) {
                account.transfer.id = "8675309"
                account.transfer.bankAccountName = "UofM Football"
                account.transfer.bankAccountNumberLastFourDigit = "1776"
                account.transfer.amount = 500
                account.transfer.payment = false
            }
        }

        return account
    }

    /**Creates an account, returns the NEW_ACCOUNT, if overload is provided then returns NEW_ACCOUNT + the overloaded keys 
     * @param overload - Any overloads to the default that needs to be added.
     * @returns - The newly created account
    */
    addAccount = (overload: Partial<IAccountDetailsExtended> = {}): IAccountDetailsExtended => {
        const NEW_ACCOUNT: IAccountDetailsExtended = this.initAccount();

        const ACCOUNT = { ...NEW_ACCOUNT, ...overload };
        this.accounts.set(ACCOUNT.accountId as string, ACCOUNT)
        this.transactions.set(ACCOUNT.accountId as string, []);
        return ACCOUNT;
    }

    /**
     * Creates an external account, returns the NEW_EXTERNAL_ACCOUNT. If overload is provided, then returns NEW_EXTERNAL_ACCOUNT + the overloaded keys
     * @param overload - Any overloads to the default that needs to be added.
     * @status - 'Verified' | 'Pending' | 'Unverified' | 'Fraud' |'Invalid'
     * @returns - The newly created external account.
     */
    addExternalAccount = (overload: Partial<ExternalBankAccounts> = {}) => {
        const NEW_EXTERNAL_ACCOUNT: ExternalBankAccounts = {
            externalAccountId: crypto.getRandomValues(new Uint32Array(5)).toString(),
            accountLast4DigitNumber: "1294",
            accountNickName: "Groceries Fund",
            accountTitle: "Bank of America Gold Checking",
            accountType: "SAV",
            bankName: "Bank of America",
            routingNumber: "101100964",
            status: "Verified"
        }

        const EXTERNAL_ACCOUNT = { ...NEW_EXTERNAL_ACCOUNT, ...overload };
        this.externalaccounts.set(EXTERNAL_ACCOUNT.externalAccountId as string, EXTERNAL_ACCOUNT as ExternalBankAccounts)
        return EXTERNAL_ACCOUNT;
    }

    /**Add loyalty rate to an account */
    addLoyaltyRate(accountID: string) {
        const account = this.accounts.get(accountID)
        if (account) {
            account.rewardActive = true
            this.accounts.set(accountID, account)
        }
    }

    /**
     * Appends an additional co owner
     * @param overload - Any overloads to the default that needs to be added.
     * @returns - The newly created external account.
     */
    addCoOwner = (accountID: string, name: string, role: "Owner" | "Co-owner") => {
        const NEW_COOWNER: OwnershipDetails = {
            name: name,
            role: role
        }

        const account = this.accounts.get(accountID)
        if (account && account.ownershipDetails) {
            account.ownershipDetails.push(NEW_COOWNER)
            this.accounts.set(accountID, account)
        }

        return NEW_COOWNER
    }

    /**
     * Closes an existing account
     */
    closeAccount = (accountID: string) => {
        const account = this.accounts.get(accountID)
        if (account) {
            account.status = "Closed"
            account.closedDate = new Date().toLocaleString()
            account.currentBalance = 0
            account.availableBalance = 0
        }
    }

    /**
     * Creates a interest setting for a CD to a external account
     * @param transferFromAccountId - The CD (accountid) to transfer from
     * @param transferToAccountId - The external account id to tranfer to
     */
    addExternalInterestSettings = (transferFromAccountId: string, transferToAccountId: string) => {
        const EXTERNAL_ACCOUNT = this.externalaccounts.get(transferToAccountId);
        const INTERNAL_ACCOUNT = this.accounts.get(transferFromAccountId);
        if (!EXTERNAL_ACCOUNT) {
            throw new Error(`MockDigitalBankAPIS -> addExternalInterestSettings -> External Account${transferToAccountId} needs to be added before calling addExternalInterestSettings`)
        }

        if (EXTERNAL_ACCOUNT.status !== 'Verified') {
            throw new Error(`MockDigitalBankAPIS -> addExternalInterestSettings -> External Account ${transferToAccountId} doesn't have a verified status`);
        }
        if (!INTERNAL_ACCOUNT) {
            throw new Error(`MockDigitalBankAPIS -> addExternalInterestSettings -> Internal Account ${transferFromAccountId} needs to be added before calling addExternalInterestSettings`)
        }
        const INTEREST_SETTINGS: IExternalInterestSettings = {
            acctNickName: EXTERNAL_ACCOUNT.accountNickName,
            accountType: EXTERNAL_ACCOUNT.accountType === 'SAV' ? 'Savings' : 'Checking',
            accountLastFourDigit: EXTERNAL_ACCOUNT.accountLast4DigitNumber,
            transferAccountId: transferToAccountId,
            accountSubType: 'External',
            interestPaymentType: 'Transfer'
        }

        INTERNAL_ACCOUNT.interestSettings = INTEREST_SETTINGS;
        this.accounts.set(transferFromAccountId, INTERNAL_ACCOUNT);
        return INTERNAL_ACCOUNT;
    }

    /**
     * Creates a interest setting for a CD to an internal account
     * @param transferFromAccountId - The CD (accountid) to transfer from
     * @param transferToAccountId - The internal account id to transfer to
     */
    addInternalInterestSettings = (transferFromAccountId: string, transferToAccountId: string) => {
        const TO_ACCOUNT = this.accounts.get(transferToAccountId);
        const FROM_ACCOUNT = this.accounts.get(transferFromAccountId);
        if (!TO_ACCOUNT) {
            throw new Error(`MockDigitalBankAPIS -> addInternalInterestSettings -> ${transferToAccountId} needs to be added before calling addInternalInterestSettings`)
        }
        if (!FROM_ACCOUNT) {
            throw new Error(`MockDigitalBankAPIS -> addInternalInterestSettings -> ${transferFromAccountId} needs to be added before calling addInternalInterestSettings`)
        }
        const ACCOUNT_NUMBER = TO_ACCOUNT.accountNumber
        const INTEREST_SETTINGS: IInternalInterestSettings = {
            acctNickName: TO_ACCOUNT.accountNickName as string,
            accountType: TO_ACCOUNT.accountType === 'CD' ? 'CD' : 'Savings',
            accountLastFourDigit: ACCOUNT_NUMBER.slice(ACCOUNT_NUMBER.length - 4),
            transferAccountId: transferToAccountId,
            accountSubType: 'Internal',
            interestPaymentType: transferFromAccountId === transferToAccountId ? 'Capitalize' : 'Transfer'
        }

        FROM_ACCOUNT.interestSettings = INTEREST_SETTINGS;
        this.accounts.set(transferFromAccountId, FROM_ACCOUNT);
    }

    /**
     * Creates a notification
     * @param accountId - the ID of the account corresponding to the notification
     * @param overload - Any overloads to the default notification
     * @returns the newly created notification
     */
    addNotification = (accountId: string, overload: Partial<INotificationServiceObject> = {}) => {
        const ACCOUNT = this.accounts.get(accountId);
        if (!ACCOUNT) {
            throw new Error(`MockDigitalBankAPIS -> addAccountNotifications -> Internal Account ${accountId} needs to be added before calling addAccountNotifications`)
        }
        const NEW_NOTIFICATION: INotificationServiceObject = {
            "notificationId": crypto.getRandomValues(new Uint32Array(5)).toString(),
            "notificationTitle": "Congratulations on the rate increase!",
            "notificationBody": "You achieved the Loyalty Rate increase of 5 basis points.",
            "notificationLinkText": "",
            "notificationUrl": "",
            "notificationType": "LoyaltyRate",
            "notificationIndicator": "Information",
            "notificationLevel": "Account",
            "accountId": accountId,
            "priority": 45,
            "dismissible": false
        }
        const NOTIFICATION = { ...NEW_NOTIFICATION, ...overload };
        this.notifications.push(NOTIFICATION);
        return NOTIFICATION;
    }

    /**Creates a base Beneficiary to be added, that is all the shared fields amongst all beneficiaries 
     * @returns - Base beneficiary object.
    */
    private createBaseBeneficiary = () => {
        return {
            id: crypto.getRandomValues(new Uint32Array(4)).toString(),
            address1: "1234 Wolverine Way",
            address2: "",
            city: "Ann Arbor",
            state: "MI",
            zipCode: "48104",
            isDeleted: false,
            phoneNumber: "555-555-5555"
        }
    }

    /**
     * Creates a Person type of IBeneficiary, adds it to the account MAP and returns the newly created beneficary
     * @param accountID - The `accountID` to assosiate the beneficary with.
     * @param overload - Any overloads to the default that needs to be added.
     * @returns - The newly created beneficary.
     */
    addPersonBeneficiary = (accountID: string, overload: Partial<IBeneficiary> = {}) => {
        const NEW_PERSON: IBeneficiary = {
            ...this.createBaseBeneficiary(),
            name: "Thomas Jefferson",
            firstName: "Thomas",
            middleName: "",
            lastName: "Jefferson",
            percentage: 1,
            dateOfBirth: "04/13/1743",
            relationship: "Friend",
            type: 'Person',
            email: "t.jefferson@gmail.com",
            ssn: "111-11-1111",
            ...overload
        }

        const account = this.accounts.get(accountID)
        if (account) {
            account.beneficiaries.push(NEW_PERSON)
            this.accounts.set(accountID, account)
        }

        return NEW_PERSON
    }

    /**
     * Creates a Trust type of IBeneficiary, adds it to the account MAP and returns the newly created beneficary
     * @param accountID - The `accountID` to assosiate the beneficary with.
     * @param overload - Any overloads to the default that needs to be added.
     * @returns - The newly created beneficary.
     */
    addTrustBeneficiary = (accountID: string, overload: Partial<IBeneficiary> = {}) => {
        const NEW_TRUST: IBeneficiary = {
            ...this.createBaseBeneficiary(),
            name: "Credit One Trust",
            trustName: "Credit One Trust",
            trustEstablishedDate: "01/01/2017",
            tin: "11-1111111",
            type: "Trust",
            percentage: 1,
            ...overload
        }

        const account = this.accounts.get(accountID)
        if (account) {
            account.beneficiaries.push(NEW_TRUST)
            this.accounts.set(accountID, account)
        }
        return NEW_TRUST
    }


    /**
     * Creates a Charity type of IBeneficiary, adds it to the account MAP and returns the newly created beneficary
     * @param accountID - The `accountID` to assosiate the beneficary with.
     * @param overload - Any overloads to the default that needs to be added.
     * @returns - The newly created beneficary.
     */
    addCharityBeneficiary = (accountID: string, overload: Partial<IBeneficiary> = {}) => {
        const NEW_CHARITY: IBeneficiary = {
            ...this.createBaseBeneficiary(),
            name: "Save the trees",
            charityName: "Save the trees",
            tin: "11-1111111",
            email: "saveTheTrees@gmail.com",
            type: "Charity",
            percentage: 1,
            ...overload
        }

        const account = this.accounts.get(accountID)
        if (account) {
            account.beneficiaries.push(NEW_CHARITY)
            this.accounts.set(accountID, account)
        }
        return NEW_CHARITY;
    }

    /**Creates a transaction for a specific account, returns the NEW_TRANSACTION, if overload is provided then returns NEW_TRANSACTION + the overloaded keys */
    addTransaction = (overload: Partial<ITransactionHistoryDataMock> = {}, accountID: string) => {
        const NEW_TRANSACTIONS: ITransactionHistoryDataMock = {
            amount: 124,
            currentBalance: 124,
            postDate: "2023-03-31",
            memo: 'Interest Paid',
            institution: 'DagBank',
            id: crypto.getRandomValues(new Uint32Array(4)).toString(),
            transactionId: crypto.getRandomValues(new Uint32Array(5)).toString(),
            transactionType: 'Credit',
            description: 'Interest Paid',
            isAmountHold: false,
            ...overload
        }

        const account = this.accounts.get(accountID);

        if (account) {
            this.transactions.set(accountID, [...this.transactions.get(accountID) || [], NEW_TRANSACTIONS])
        }

        return NEW_TRANSACTIONS
    }


    addDocument = (accountId: string, docType: TDocumentType, overload: Partial<IDocument> = {}) => {
        const NEW_DOCUMENT: IDocumentExtended = {
            documentId: crypto.getRandomValues(new Uint32Array(5)).toString(),
            date: new Date().toLocaleDateString(),
            type: docType,
            ...overload
        }

        if (docType === 'TAX') {
            this.taxDocuments.push(NEW_DOCUMENT);
        }
        else {
            const ACCOUNT_DOCUMENTS = this.documents.get(accountId) || [];
            ACCOUNT_DOCUMENTS.push(NEW_DOCUMENT);
            this.documents.set(accountId, ACCOUNT_DOCUMENTS);
        }
    }



    addTransferHistory = (id: string, fromAccountOverload: Partial<ITransferAccount> = {}, toAccountOverload: Partial<ITransferAccount> = {}, detailsOverload: Partial<ITransferDetails> = {}) => {
        const NEW_TRANSFER = {
            fromAccount: {
                name: "JHYS2023",
                id: "123456abc",
                routingNumber: "125402133",
                lastFourDigits: "0919",
                accountType: "internal",
                ...fromAccountOverload
            },
            toAccount: {
                name: "JHYS3023",
                id: "123456abc",
                routingNumber: "125214133",
                lastFourDigits: "1909",
                accountType: "internal",
                ...toAccountOverload
            },
            transferDetails: {
                transferId: id,
                orderReference: "naWgV9I",
                transferDate: "2023-07-10T20:17:03.290931Z",
                transferAmount: "173800",
                transferStatus: "Completed",
                transferType: "3",
                transferNetwork: "ACH",
                transferOrigin: "1",
                isInitialFund: false,
                isCancelAllowed: true,
                ...detailsOverload
            }
        }
        this.transferHistory.push(NEW_TRANSFER)
        return this.transferHistory
    };

    //Todo make overloads an options object
    addScheduledTransfers = (transferId: string, fromAccountOverload: Partial<ITransferAccount> = {}, toAccountOverload: Partial<ITransferAccount> = {}, detailsOverload: Partial<ITransferDetails> = {}) => {
        const NEW_TRANSFER = {
            fromAccount: {
                name: "JHYS2023",
                id: "123456abc",
                routingNumber: "125402133",
                lastFourDigits: "0919",
                accountType: "internal",
                ...fromAccountOverload
            },
            toAccount: {
                name: "JHYS3023",
                id: "123456abb",
                routingNumber: "125214133",
                lastFourDigits: "1909",
                accountType: "internal",
                ...toAccountOverload
            },
            transferDetails: {
                transferId: transferId,
                orderReference: "naWgV9I",
                transferDate: "2023-07-10T20:17:03.290931Z",
                transferAmount: "173800",
                transferStatus: "Completed",
                transferType: "3",
                transferNetwork: "ACH",
                transferOrigin: "1",
                isInitialFund: false,
                isCancelAllowed: true,
                ...detailsOverload
            }
        }
        this.scheduledTransfers.push(NEW_TRANSFER)
        return this.scheduledTransfers
    };

    spy = () => { }
}




/********************
 *
 * EXAMPLE FOR A TEST
 *
 *
*/

// it('testing', () => {
//     //* Creates the class and creates a new user
//     const context = new MockDigitalBankAPIs();

//     //* Creates an account
//     //* The addAccount function returns the newly created account
//     //* You would only overload any values that you'll need to test for.
//     const ACCOUNT = context.addAccount({
//         accountTitle: 'Test Account'
//     });

//     //* Adds a person beneficary to the new account created.
//     context.addPersonBeneficiary(ACCOUNT.id);

//     //* Creates another account
//     const ACCOUNT2 = context.addAccount({
//         accountTitle: 'Test 2',
//         accountClosed: true
//     })

//     //* Add a transaction to the second account.
//     context.addTransaction(ACCOUNT2.id);

//     //* This function will mock all of the axios and return the correct response based on the passed in MockDigitalBankAPIs
//     spyOnAxios(context);

//     //* After this we should be able to run tests without having to mock anything else as long as we render all of the contexts that are need. IE render App and go to a page.
// })
